import { gql, TypedDocumentNode } from '@apollo/client';

import { TPartnerFieldsResponse, TPartnerFieldsFilterInput } from '../types';

const GET_PARTNER_FIELDS_DATA: TypedDocumentNode<
	TPartnerFieldsResponse,
	TPartnerFieldsFilterInput
> = gql`
	query getPartnerFields($filterBy: PartnerFieldFilterInput) {
		getPartnerFields(filterBy: $filterBy) {
			fields {
				field {
					areaSize
					id
					varieties {
						id
						varietyCategory {
							id
							name
							localizedName
							label
							categoryType
							categoryGroup
							faoCode
							siexCode
							swissCode
							swissLanguage
							germanyCode
							discriminator
						}
						percentage
					}
				}
			}
		}
	}
`;

export default GET_PARTNER_FIELDS_DATA;
