import React, { FC, useCallback } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import Text from 'UI/display/Text';
import Modal from 'UI/display/modal/dialog';
import Button from 'UI/inputs/Button';
import { enqueueSnackbar } from 'notistack';

import useProfile from 'Hooks/useProfile';

interface ResetPasswordModalProps {
	handleClose: () => void;
}

export const ResetPasswordModal: FC<ResetPasswordModalProps> = ({
	handleClose,
}) => {
	const { t } = useTranslation();
	const { isAuthenticated, logout } = useAuth0();
	const { profile } = useProfile();

	const handleForgotPassword = useCallback(() => {
		if (!isAuthenticated) {
			return null;
		}

		const returnTo =
			process.env.AUTH0_LOGOUT_URI || window.location.origin + '/logout.html';

		void fetch(
			`https://${process.env.AUTH0_DOMAIN}/dbconnections/change_password`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					client_id: process.env.AUTH0_CLIENT_ID,
					email: profile?.email,
					connection: 'Username-Password-Authentication',
				}),
			}
		)
			.then((res) => {
				if (res.status === 200) {
					enqueueSnackbar(t('info.resetPasswordSuccess'), {
						variant: 'success',
					});
					setTimeout(() => void logout({ logoutParams: { returnTo } }), 10000);
				} else {
					enqueueSnackbar(t('common.fallbackComponent.somethingWentWrong'), {
						variant: 'error',
					});
				}
				handleClose();
			})
			.catch(() => {
				enqueueSnackbar(t('common.fallbackComponent.somethingWentWrong'), {
					variant: 'error',
				});
			});
	}, [handleClose, isAuthenticated, logout, profile?.email, t]);

	return (
		<Modal.UncontrolledModal open={true} onClose={handleClose}>
			<Modal.DialogTitle
				title={t('labels.resetPassword')}
				closeIconHandler={handleClose}
			/>
			<Modal.DialogContent>
				<Text>
					{t('resetPassword.description', { emailAddress: profile?.email })}
				</Text>
			</Modal.DialogContent>
			<Modal.DialogActions sx={{ width: '100%', marginTop: '1.5rem' }}>
				<Button
					variant="text"
					onClick={handleClose}
					sx={{
						position: 'absolute',
						left: '1rem',
					}}
				>
					{t('buttons.cancel')}
				</Button>
				<Button
					variant="outlined"
					disabled={!isAuthenticated}
					onClick={handleForgotPassword}
				>
					{t('buttons.confirm')}
				</Button>
			</Modal.DialogActions>
		</Modal.UncontrolledModal>
	);
};
