import isNull from 'vet/isNull';
import isOneOf from 'vet/isOneOf';
import isNumber from 'vet/numbers/isNumber';
import isShape from 'vet/objects/isShape';
import optional from 'vet/optional';
import isString from 'vet/strings/isString';
import ValidatorType from 'vet/types/ValidatorType';

export const varietySchema = {
	name: isString,
	varietyCategory: {
		id: isString,
		name: isString,
		localizedName: isString,
		label: isString,
		categoryType: isString,
		categoryGroup: isString,
		faoCode: isNumber,
		siexCode: isNumber,
		swissCode: isNumber,
		swissLanguage: isString,
		germanyCode: isString,
		discriminator: isString,
	},
	percentage: optional(isNumber),
	sowDate: isOneOf(isString, isNull),
};

const _isVariety = isShape(varietySchema);
const isVariety: typeof _isVariety = _isVariety;

export type TVariety = ValidatorType<typeof isVariety>;
export default isVariety;
