import React from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { getFieldCategory } from 'Models/fields/getFieldCategory';
import { TJob } from 'Models/jobs/isJob';
import { TJobFieldSnapshot } from 'Models/jobs/isJobFieldSnapshot';
import jobCausesString from 'Models/jobs/jobCausesString';
import jobDateCompletedString from 'Models/jobs/jobDateCompletedString';
import jobDriverEquipmentString from 'Models/jobs/jobDriverEquipmentString';
import jobDriversString from 'Models/jobs/jobDriversString';
import jobEarliestHarvestPermitted from 'Models/jobs/jobEarliestHarvestPermitted';
import jobProductDetails from 'Models/jobs/jobProductDetails';
import jobTreatment from 'Models/jobs/jobTreatment';
import jobWaterConsumptionString from 'Models/jobs/jobWaterConsumptionString';
import { TLocation } from 'Models/locations/isLocation';
import { AreaUnits } from 'Models/units';
import convertAreaUnit from 'Models/units/convertAreaUnit';
import { toDisplayString } from 'Models/units/transpileUnits';
import { TBasicWeather, TCurrentWeather } from 'Models/weather';
import getPrecipitationString from 'Models/weather/getPrecipitationString';
import getTemperature from 'Models/weather/getTemperature';
import getWindSpeedString from 'Models/weather/getWindSpeedString';
import Table from 'UI/display/Table';
import Text from 'UI/display/Text';

import FieldLogHeaderSubtitles from './FieldLogsHeaderSubtitles';
import styles from './styles.module.scss';

const getWeather = (weather?: TBasicWeather | TCurrentWeather | null) => {
	if (weather?.temp) {
		return `
			${getTemperature(weather.temp).tempValue} -
			${getWindSpeedString(weather.windSpeed)} -
			${getPrecipitationString(weather.rain)}
		`;
	}

	return null;
};

export type TFieldLogsTableJobs = Pick<
	TJob,
	| 'completedDate'
	| 'driversWithEquipmentConfig'
	| 'treatments'
	| 'jobType'
	| 'customJobType'
	| 'jobCauses'
	| 'weather'
	| 'jobFields'
>[];

export type TFieldLogsTableField = Pick<
	TJobFieldSnapshot,
	'areaSize' | 'productiveArea' | 'name' | 'varieties' | 'id'
>;

type TFieldLogsTableProps = {
	jobs: TFieldLogsTableJobs;
	field: TFieldLogsTableField;
	location?: Pick<TLocation, 'areaUnit'> | null;
};

const FieldLogsTable = (props: TFieldLogsTableProps) => {
	const { field, jobs, location } = props;
	const { t } = useTranslation();
	const prefix = 'treatmentLogsExportPage.sprayLog.tableHeaders.';

	const areaUnit = location?.areaUnit || AreaUnits.HECTARE;
	const fieldCategory = getFieldCategory(field, t);

	const fieldAreaSize = field.areaSize
		? `${convertAreaUnit(field.areaSize, areaUnit)} ${
				toDisplayString(areaUnit) || ''
		  }`
		: null;

	const productiveAreaSize = field.productiveArea
		? `${convertAreaUnit(field.productiveArea, areaUnit)} ${
				toDisplayString(areaUnit) || ''
		  }`
		: null;

	const notApplicableLabel = t('labels.notApplicable');

	const header = (
		<Table.Head>
			<Table.Row>
				<Table.Cell colSpan={9} className={styles.tableHeading}>
					<Text variant="label2">{field.name}</Text>
					<FieldLogHeaderSubtitles>
						{fieldCategory}
						{fieldAreaSize}
						{productiveAreaSize}
					</FieldLogHeaderSubtitles>
				</Table.Cell>
			</Table.Row>
			<Table.Row className={styles.row}>
				<Table.Cell>{t(`${prefix}date`)}</Table.Cell>
				<Table.Cell>{t(`${prefix}operator`)}</Table.Cell>
				<Table.Cell>{t(`${prefix}equipment`)}</Table.Cell>
				<Table.Cell>{t(`${prefix}treatment`)}</Table.Cell>
				<Table.Cell>{t(`${prefix}causes`)}</Table.Cell>
				<Table.Cell>{t(`${prefix}products`)}</Table.Cell>
				<Table.Cell>{t(`${prefix}weather`)}</Table.Cell>
				<Table.Cell>{t(`${prefix}firstPermittedHarvest`)}</Table.Cell>
				<Table.Cell>{t(`${prefix}water`)}</Table.Cell>
			</Table.Row>
		</Table.Head>
	);

	const rows = jobs.map((job, i) => {
		const date = jobDateCompletedString(job);
		const driver = jobDriversString(job);
		const equipment = jobDriverEquipmentString(job);
		const treatment = jobTreatment(job, t);
		const causes = jobCausesString(job);
		const products = jobProductDetails(job);
		const weather = getWeather(job.weather) || notApplicableLabel;
		const firstPermittedHarvest = jobEarliestHarvestPermitted(job);
		const water = jobWaterConsumptionString(job, location, [field.id]);

		return (
			<Table.Row key={'field-logs-table' + i} className={styles.row}>
				<Table.Cell>{date}</Table.Cell>
				<Table.Cell>{driver}</Table.Cell>
				<Table.Cell>{equipment}</Table.Cell>
				<Table.Cell>{treatment}</Table.Cell>
				<Table.Cell>{causes}</Table.Cell>
				<Table.Cell>{products}</Table.Cell>
				<Table.Cell>{weather}</Table.Cell>
				<Table.Cell>{firstPermittedHarvest}</Table.Cell>
				<Table.Cell>{water}</Table.Cell>
			</Table.Row>
		);
	});

	return (
		<Table.Table>
			{header}
			<Table.Body>{rows}</Table.Body>
		</Table.Table>
	);
};

export default FieldLogsTable;
