import React from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import Divider from 'UI/display/Divider';
import Text from 'UI/display/Text';
import LoadingSpinner from 'UI/icons/LoadingSpinner';
import Checkbox from 'UI/inputs/Checkbox';
import Box from 'UI/layout/Box';
import Menu from 'UI/navigation/Menu';

type TDropdownContent = {
	loading?: boolean;
	dropdownItems?: string[] | { name: string; id: string }[];
	appliedFilters?: string[];
	onChange: (dataItem: string, active: boolean) => void;
	onClear?: () => void;
};

export const DROPDOWN_ITEM_DIVIDER = 'DROPDOWN_ITEM_DIVIDER';

const DropdownContent = (props: TDropdownContent) => {
	const { loading, dropdownItems, appliedFilters, onClear, onChange } = props;
	const { t } = useTranslation();

	if (loading) {
		return (
			<Box textAlign="center">
				<LoadingSpinner width={24} height={24} />
			</Box>
		);
	}

	const clearAllItem =
		appliedFilters?.length && onClear
			? [
					<Divider sx={{ marginY: '0.5rem' }} key="divider" />,
					<Menu.Item onClick={onClear} key="clearAll">
						<Text variant="body2" color="textSecondary">
							{t('buttons.clearAll')}
						</Text>
					</Menu.Item>,
			  ]
			: null;

	return (
		<>
			{dropdownItems?.map((dropdownItem) => {
				const isDivider = dropdownItem === DROPDOWN_ITEM_DIVIDER;
				const isActive =
					(dropdownItem?.id
						? appliedFilters?.includes(dropdownItem.id)
						: appliedFilters?.includes(dropdownItem)) || false;
				const label = dropdownItem?.name ? dropdownItem.name : dropdownItem;
				const value = dropdownItem?.id ? dropdownItem.id : dropdownItem;

				return isDivider ? (
					<Divider sx={{ marginY: '0.5rem' }} key="divider" />
				) : (
					<Box paddingLeft="1rem" key={dropdownItem}>
						<Checkbox.Controlled
							label={label}
							checked={isActive}
							onChange={() => onChange(value, isActive)}
							size="small"
							dense
						/>
					</Box>
				);
			})}
			{clearAllItem}
		</>
	);
};

export default DropdownContent;
