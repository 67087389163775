import React, { useEffect } from 'react';

import { ApolloError, useQuery } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { THarvestExportHeadersDictionary } from 'Features/localization/lang/types/exportPages';
import { SubscriptionType } from 'Models/locations/isLocationSubscriptions';
import { LogTypes } from 'Models/logs/LogType';
import Text from 'UI/display/Text';
import Box from 'UI/layout/Box';

import useCurrentLocation from 'Hooks/useCurrentLocation';
import useExportType from 'Hooks/useExportType';
import useManageHistory from 'Hooks/useManageHistory';
import useProfile from 'Hooks/useProfile';
import useSubscriptionsFeatureFlag from 'Hooks/useSubscriptionsFeatureFlag';

import ExportLoadingSpinner from '../../../common/components/ExportLoadingSpinner';
import LogsExportPageLayout from '../../../common/components/ExportPageLayout';
import handleExport from '../../../common/utils/handleExport';
import GET_HARVEST_EXPORT_DATA from '../api/getHarvestExportData';
import GET_TEAMS_FOR_HARVEST_TABLE_DATA from '../api/getTeamsForHarvestData';
import getExportData from '../utils/getExportData';

const LIMIT = 30;

function ExportToFile() {
	const { paramsFromURL: searchParams } = useManageHistory();
	const location = useCurrentLocation();
	const { profile } = useProfile();
	const type = useExportType();
	const { t } = useTranslation();

	const hasTimesheetsSubscription = Boolean(
		useSubscriptionsFeatureFlag(SubscriptionType.TIMESHEET)
	);

	const dictionary: THarvestExportHeadersDictionary = t(
		'exportPages.headers.harvest',
		{
			returnObjects: true,
		}
	);

	const {
		data: harvestData,
		loading: isHarvestDataLoading,
		error: harvestQueryError,
		fetchMore,
	} = useQuery(GET_HARVEST_EXPORT_DATA, {
		variables: {
			filterBy: {
				dateFrom: searchParams.filters.dateFrom,
				dateTo: searchParams.filters.dateTo,
				fieldCategoryIds: searchParams.filters.fieldCategories,
				fieldVarieties: searchParams.filters.fieldVarieties,
				fieldIds: searchParams.filters.fieldIds,
			},
			pageInput: {
				offset: 0,
				limit: LIMIT,
				sortBy: [{ direction: 'DESC', field: 'date' }],
			},
			locationId: searchParams.locationID,
		},
	});

	const {
		data: teamsData,
		loading: isTeamsDataLoading,
		error: teamsQueryError,
	} = useQuery(GET_TEAMS_FOR_HARVEST_TABLE_DATA, {
		variables: {
			locationID: searchParams.locationID,
		},
	});

	const total = harvestData?.getHarvest.totalElements || 0;
	const rawHarvestData = harvestData?.getHarvest.content;

	useEffect(() => {
		if (rawHarvestData && rawHarvestData.length < total) {
			void fetchMore({
				variables: {
					pageInput: {
						offset: rawHarvestData.length,
						limit: LIMIT,
					},
				},
			});
		}
	}, [rawHarvestData, fetchMore, total]);

	if (
		isHarvestDataLoading ||
		isTeamsDataLoading ||
		(rawHarvestData && rawHarvestData.length < total)
	) {
		return <ExportLoadingSpinner />;
	}

	if (harvestQueryError) {
		throw new ApolloError(harvestQueryError);
	}

	if (teamsQueryError) {
		throw new ApolloError(teamsQueryError);
	}

	if (!rawHarvestData?.length && total === 0) {
		return (
			<Box padding="1.5rem">
				<Text>{t('labels.noLogsFound')}</Text>
			</Box>
		);
	}

	if (rawHarvestData && profile && location && teamsData && type) {
		const harvestsWithTeam = rawHarvestData.map((harvest) => {
			if (harvest.teamIds?.length) {
				const teams =
					teamsData.getTeamsPageable.teams.filter((team) =>
						harvest.teamIds?.some((teamId) => teamId === team.id)
					) || [];

				return { ...harvest, teams };
			}

			return { ...harvest, teams: [] };
		});
		const exportData = getExportData(
			harvestsWithTeam,
			type,
			dictionary,
			hasTimesheetsSubscription
		);
		return (
			<LogsExportPageLayout
				title={t('exportPages.readyTitle', { context: type })}
				downloadTrigger={() =>
					handleExport({
						exportData,
						location: location,
						fileType: type,
						userID: profile.id,
						logType: LogTypes.Harvest,
						hasTimesheetsSubscription,
					})
				}
			/>
		);
	}

	return null;
}

export default ExportToFile;
