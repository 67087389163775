import { useEffect } from 'react';

import { MutationResult } from '@apollo/client';
import { GraphQLErrors } from '@apollo/client/errors';
import { getGraphQLErrorStatuses } from 'Models/graphql/ErrorUtils';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import throwErrorSilently from 'Utils/throwErrorSilently';

type TSnackbarNotification = {
	mutationResult: MutationResult;
	messageSuccess?: string;
	messageFailure?: string;
};

function useSnackbarNotifications(snackbarNotification: TSnackbarNotification) {
	const { mutationResult, messageSuccess, messageFailure } =
		snackbarNotification;
	const { data, error } = mutationResult;

	const { i18n } = useTranslation();
	const t = i18n.getFixedT(null, null, `errors`);

	useEffect(() => {
		if (data && messageSuccess) {
			enqueueSnackbar(messageSuccess, {
				variant: 'success',
			});
		} else if (error) {
			const statuses = getGraphQLErrorStatuses(
				error.graphQLErrors as GraphQLErrors
			);

			// Show known error messages
			if (statuses) {
				const translatedStatuses = statuses
					.filter((status) => {
						return i18n.exists(`errors.${status}`);
					})
					.map((status) => t(status));

				translatedStatuses.forEach((status) => {
					enqueueSnackbar(status, {
						variant: 'error',
					});
				});
				return;
			}

			if (messageFailure) {
				enqueueSnackbar(messageFailure, {
					variant: 'error',
				});
			}

			throwErrorSilently(error);
		}
	}, [error, data, messageSuccess, messageFailure]);
}

export default useSnackbarNotifications;
