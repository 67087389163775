import z from 'zod';

export const PartnerVarietySchema = z.object({
	name: z.string(),
	varietyCategory: z.object({
		id: z.string(),
		name: z.string(),
		localizedName: z.string(),
		label: z.string(),
		categoryType: z.string(),
		categoryGroup: z.string(),
		faoCode: z.number(),
		siexCode: z.number(),
		swissCode: z.number(),
		swissLanguage: z.string(),
		germanyCode: z.string(),
		discriminator: z.string(),
	}),
});

export const PartnerVarietyInputSchema = z.object({
	name: z.string(),
	categoryId: z.string(),
});

export type TPartnerVariety = z.infer<typeof PartnerVarietySchema>;

export type TPartnerVarietyInput = z.infer<typeof PartnerVarietyInputSchema>;
