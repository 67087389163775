import { TTranslateFunction } from 'Features/localization';
import _sortBy from 'lodash/sortBy';
import _uniqBy from 'lodash/uniqBy';

import { TPartnerFieldsResponse } from '../../common/api/getPartnerFields';

const getCategoriesFromFields = (
	t: TTranslateFunction,
	partnerFieldsResponse?: TPartnerFieldsResponse['getPartnerFields']
) =>
	_sortBy(
		_uniqBy(
			partnerFieldsResponse?.fields.flatMap((field) =>
				field.field.varieties.map((variety) => ({
					name: variety.varietyCategory.localizedName,
					id: variety.varietyCategory.id,
				}))
			),
			'id'
		),
		({ name }) => name.toLocaleLowerCase()
	);

export default getCategoriesFromFields;
