import React from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import harvestCategoryString from 'Models/harvest/harvestCategoryString';
import harvestCrewString from 'Models/harvest/harvestCrewString';
import harvestDateString from 'Models/harvest/harvestDateString';
import harvestFieldsNameString from 'Models/harvest/harvestFieldsNameString';
import harvestVariety from 'Models/harvest/harvestVariety';
import harvestVolume from 'Models/harvest/harvestVolume';
import harvestWeightWithUnit from 'Models/harvest/harvestWeightWithUnit';
import { THarvest } from 'Models/harvest/types/Harvest';
import { TLocation } from 'Models/locations/isLocation';
import Text from 'UI/display/Text';
import Grid from 'UI/layout/Grid';

import { keys } from 'Utils/objectTyped';

import DetailModalSection from '../../../../../components/ModalSection';

type THarvestDetails = Pick<
	THarvest,
	| 'author'
	| 'boxSize'
	| 'varietyCategory'
	| 'countingUnit'
	| 'date'
	| 'fields'
	| 'teamMembers'
	| 'unit'
	| 'variety'
	| 'volume'
	| 'weight'
>;

type THarvestDetailsWithLocation = THarvestDetails & {
	location: Pick<TLocation, 'id' | 'name'>;
};

type InformationProps = {
	harvest: THarvestDetails | THarvestDetailsWithLocation;
};

const getContent = function (
	harvest: THarvestDetails | THarvestDetailsWithLocation
) {
	const content = {
		date: harvestDateString(harvest),
		field: harvestFieldsNameString(harvest),
		category: harvestCategoryString(harvest),
		variety: harvestVariety(harvest),
		boxes: harvestVolume(harvest),
		harvested: harvestWeightWithUnit(harvest),
		responsible: harvestCrewString(harvest),
	};

	if ('location' in harvest) {
		return {
			date: content.date,
			location: harvest.location.name || '-',
			field: content.field,
			category: content.category,
			variety: content.variety,
			boxes: content.boxes,
			harvested: content.harvested,
			responsible: content.responsible,
		};
	}

	return content;
};

const Details = (props: InformationProps) => {
	const { harvest } = props;
	const prefix = 'logsPages.harvestLogsPage.detailsModal.';
	const { t } = useTranslation();

	const content = getContent(harvest);

	const details = keys<typeof content>(content).map((name) => (
		<Text key={name}>{t(`${prefix}props.${name}`)}: </Text>
	));

	return (
		<DetailModalSection title={t(`${prefix}sections.details`)}>
			<Grid container spacing={1}>
				<Grid item xs={6}>
					{details}
				</Grid>
				<Grid item xs={6}>
					{Object.values(content).map((value) => (
						<Text key={value}>{value}</Text>
					))}
				</Grid>
			</Grid>
		</DetailModalSection>
	);
};

export default Details;
