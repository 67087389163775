import React, { FC, useCallback } from 'react';

import { useReactiveVar } from '@apollo/client';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import Divider from 'UI/display/Divider';
import Text from 'UI/display/Text';
import Box from 'UI/layout/Box';

import { addTreatmentPageState } from '../CreateTreatmentModal/state';
import { AddTreatmentModalStatus } from '../CreateTreatmentModal/types';

export const SelectTreatmentsSourceModal: FC = () => {
	const { t } = useTranslation();
	const state = useReactiveVar(addTreatmentPageState);
	const handleSelectFromGovList = useCallback(
		() =>
			addTreatmentPageState({
				...state,
				editedCategoryId: undefined,
				status: AddTreatmentModalStatus.SELECT_GOV_TREATMENTS,
			}),
		[state]
	);

	const handleSelectCreateNew = useCallback(
		() =>
			addTreatmentPageState({
				...state,
				editedCategoryId: undefined,
				status: AddTreatmentModalStatus.SET_PRODUCT_DETAILS,
			}),
		[state]
	);

	return (
		<div>
			<Box
				sx={{ cursor: 'pointer' }}
				display="flex"
				justifyContent="space-between"
				alignItems="center"
				onClick={handleSelectFromGovList}
			>
				<Box>
					<Text variant="label">
						{t('treatmentLibrary.selectSource.from.government.title')}
					</Text>
					<Text variant="body2">
						{t('treatmentLibrary.selectSource.from.government.description')}
					</Text>
				</Box>
				<ArrowForwardIosIcon width={24} height={24} />
			</Box>
			<Divider sx={{ margin: '1rem 0' }} />
			<Box
				sx={{ cursor: 'pointer' }}
				display="flex"
				justifyContent="space-between"
				alignItems="center"
				onClick={handleSelectCreateNew}
			>
				<Box>
					<Text variant="label">
						{t('treatmentLibrary.selectSource.from.new.title')}
					</Text>
					<Text variant="body2">
						{t('treatmentLibrary.selectSource.from.new.description')}
					</Text>
				</Box>
				<ArrowForwardIosIcon width={24} height={24} />
			</Box>
		</div>
	);
};
