import { gql, TypedDocumentNode } from '@apollo/client';
import {
	TPartnerLocation,
	TPartnershipLocationStatus,
} from 'Models/partnershipCompany/isPartnerLocation';
import { TPartnerVarietyInput } from 'Models/planner/PartnerVariety';

export type TPartnerLocationsResponse = {
	getPartnerLocations: Pick<
		TPartnerLocation,
		'id' | 'name' | 'status' | 'farmerEmail'
	>[];
};

export type TGetPartnerLocationsInput = {
	filterBy?: {
		statuses?: TPartnershipLocationStatus[];
		variety?: TPartnerVarietyInput;
	};
};

const GET_PARTNERSHIP_LOCATIONS_DATA: TypedDocumentNode<
	TPartnerLocationsResponse,
	TGetPartnerLocationsInput
> = gql`
	query getPartnerLocations($filterBy: PartnerLocationFilterInput) {
		getPartnerLocations(filterBy: $filterBy) {
			id
			name
			status
			farmerEmail
		}
	}
`;

export default GET_PARTNERSHIP_LOCATIONS_DATA;
