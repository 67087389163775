import { useMemo } from 'react';

import { useQuery } from '@apollo/client';
import { LocationType } from 'Models/locations/isLocation';

import { GET_CATEGORIES } from './api/getCategories';

export const useGetCategoryNameMap = () => {
	const { data } = useQuery(GET_CATEGORIES, {
		variables: { filterBy: { discriminator: LocationType.GLOBAL } },
	});

	return useMemo(() => {
		const map = {};

		data?.getCategories
			.map(({ id, localizedName }) => ({
				id,
				localizedName,
			}))
			.forEach(({ id, localizedName }) => (map[id] = localizedName));

		return map;
	}, [data?.getCategories]);
};
