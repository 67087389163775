import React, { useMemo, useState } from 'react';

import { ApolloError, useQuery } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { TVarietyCategory } from 'Models/fields/isFieldVariety';
import Text from 'UI/display/Text';
import ArrowDropDownIcon from 'UI/icons/ArrowDropDown';
import Box from 'UI/layout/Box';
import Menu from 'UI/navigation/Menu/menu';

import useManageHistory from 'Hooks/useManageHistory';
import getPartnerVarietyFromURL from 'Utils/getPartnerVarietyFromURL';

import GET_PARTNER_VARIETIES_DATA from '../../../../../features/api/getPartnerVarieties';
import CollapsedCategory from './CollapsedCategory';
import styles from './styles.module.scss';

const dropdownStyles = {
	left: '0.5rem',
	top: '-0.25rem',
	'.MuiList-root': {
		padding: 0,
		maxHeight: '18.125rem',
	},
};

export default function CategoryAndVarietyDropdown() {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const { t } = useTranslation();

	const { historyPush, paramsFromURL } = useManageHistory();

	const selectedValue = getPartnerVarietyFromURL(paramsFromURL);
	const onCategoryClick = (variety: {
		varietyCategoryId: string;
		varietyCategoryName: string;
	}) => {
		const newSearchParams = {
			...paramsFromURL,
			filters: {
				...paramsFromURL.filters,
				partnerVarietyCategory: variety.varietyCategoryId,
				partnerVarietyName: variety.varietyCategoryName,
			},
		};

		historyPush(newSearchParams);
	};

	const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const { data, loading, error } = useQuery(GET_PARTNER_VARIETIES_DATA);

	const varietiesByCategories: {
		name: string;
		varietyCategories: TVarietyCategory[];
	}[] = useMemo(() => {
		const result = data?.getPartnerVarieties.reduce((acc, cur) => {
			const { name, varietyCategory } = cur;
			if (!acc[name]) {
				// If the name doesn't exist in the accumulator, create a new entry
				acc[name] = {
					name: name,
					varietyCategories: [],
				};
			}

			acc[name].varietyCategories.push(varietyCategory);

			return acc;
		}, {});

		return result ? Object.values(result) : [];
	}, [data]);

	const selectedCategoryName = useMemo(
		() =>
			data?.getPartnerVarieties.find(
				({ varietyCategory }) =>
					varietyCategory.id === selectedValue?.categoryId
			)?.varietyCategory.localizedName,
		[data?.getPartnerVarieties, selectedValue]
	);

	if (loading) {
		return null;
	}

	if (error) {
		throw new ApolloError(error);
	}

	if (data) {
		const collapsedCategories = varietiesByCategories?.map((item, idx) => {
			return (
				<CollapsedCategory
					variety={item.name}
					varietyCategories={item}
					selectedValue={selectedValue}
					onChangeHandler={onCategoryClick}
					key={item.name + idx}
				/>
			);
		});

		const selectCropTypeLabel = (
			<div className={styles.selectCropTypeLabel}>
				<Text variant="label">{t('plannerPage.cropTypeDropdownTitle')}</Text>
			</div>
		);

		return (
			<>
				<Box className={styles.dropdownBox} onClick={handleClick}>
					<p className={styles.dropdownTitle}>
						{selectedValue
							? `${selectedCategoryName}: ${selectedValue.name}`
							: t('plannerPage.cropTypeDropdownTitle')}
					</p>
					<ArrowDropDownIcon />
				</Box>
				<Menu
					id="navbar-profile-menu"
					anchorEl={anchorEl}
					transitionDuration={0}
					open={open}
					sx={dropdownStyles}
					onClose={handleClose}
					MenuListProps={{
						'aria-labelledby': 'navbar-profile-menu',
						dense: true,
					}}
				>
					{selectCropTypeLabel}
					{collapsedCategories}
				</Menu>
			</>
		);
	}

	return null;
}
