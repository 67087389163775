import { TVarietyCategory } from 'Models/fields/isFieldVariety';
import _flattenDeep from 'lodash/flattenDeep';
import _uniqBy from 'lodash/uniqBy';

import { TVariety } from './isVariety';

function getLocationVarietyCategories(
	fields: {
		varieties?: Pick<TVariety, 'varietyCategory'>[] | null;
	}[]
): TVarietyCategory[] {
	return _uniqBy(
		_flattenDeep(
			fields.map(
				(field) =>
					field.varieties?.map((variety) => variety.varietyCategory) || []
			)
		),
		'id'
	);
}

export default getLocationVarietyCategories;
