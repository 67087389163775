import standardFormat from 'Models/dates/standardFormat';
import { TSupportedJobs } from 'Models/jobs/JobInput';
import { TPartnerVarietyInput } from 'Models/planner/PartnerVariety';
import { GRAM_PER_100_LITER, ProductUnits } from 'Models/units';

const getInitialInput = (
	jobType: TSupportedJobs,
	variety: TPartnerVarietyInput
) => {
	return {
		variety,
		jobDetails: {
			jobType: jobType,
			treatments: [
				{
					amount: 0,
					unit: ProductUnits[GRAM_PER_100_LITER],
					originalTreatmentId: '',
					productName: '',
				},
			],
			causeIds: [],
			justificationIds: [],
		},
		dueDate: standardFormat(new Date()),
	};
};

export default getInitialInput;
