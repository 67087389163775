import React, { useState } from 'react';

import { ApolloError, useQuery } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { URLFiltersType } from 'Models/history/isURLFilters';
import Permissions from 'Models/permissions/Permissions';
import ArrowDropDownIcon from 'UI/icons/ArrowDropDown';
import Button from 'UI/inputs/Button';
import Menu from 'UI/navigation/Menu';

import useLocationID from 'Hooks/useLocationID';
import usePermission from 'Hooks/usePermission';

import DropdownContent from '../DropdownContent';
import {
	GET_FIELDS_QUERY,
	GET_FIELDS_ADVISOR_QUERY,
} from '../common/api/getFields';
import parseGetFieldsResult from '../common/utils/parseGetFieldsResult';
import { getCategoriesWithIdsFromFields } from './utils/getCategoriesFromFields';

type TCategoriesProps = {
	filters: URLFiltersType;
	updateFilters: (filters: URLFiltersType) => void;
};

const Categories = (props: TCategoriesProps) => {
	const { filters, updateFilters } = props;
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const { t } = useTranslation();
	const locationId = useLocationID();
	const hasPermissionsToRegularQuery = usePermission(
		Permissions.GET_REGULAR_FIELD_CATEGORIES_QUERY
	);

	const { data, loading, error } = useQuery(
		hasPermissionsToRegularQuery ? GET_FIELDS_QUERY : GET_FIELDS_ADVISOR_QUERY,
		{
			variables: {
				locationId: locationId,
			},
		}
	);

	const open = Boolean(anchorEl);
	const appliedCategories =
		filters.fieldCategories || filters.categoryIds || [];

	const handleClick = (event) => setAnchorEl(event.currentTarget);
	const handleClose = () => setAnchorEl(null);

	const dropdownContentData = getCategoriesWithIdsFromFields(
		parseGetFieldsResult(data)
	);

	if (error) {
		throw new ApolloError(error);
	}

	const onItemClick = (category: string, active: boolean) => {
		const newCategories = active
			? appliedCategories.filter(
					(appliedCategory) => appliedCategory !== category
			  )
			: [...appliedCategories, category];

		updateFilters({
			...filters,
			categoryIds: newCategories,
			fieldCategories: newCategories,
		});
	};

	const onClear = () => {
		updateFilters({
			...filters,
			categoryIds: undefined,
			fieldCategories: undefined,
		});
	};

	return (
		<>
			<Button
				endIcon={<ArrowDropDownIcon />}
				variant="secondary"
				onClick={handleClick}
			>
				{t('buttons.category')}
				{appliedCategories?.length ? `(${appliedCategories?.length})` : ''}
			</Button>
			<Menu.Menu
				id="category-select"
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
			>
				<DropdownContent
					loading={loading}
					dropdownItems={dropdownContentData}
					onChange={onItemClick}
					onClear={onClear}
					appliedFilters={appliedCategories}
				/>
			</Menu.Menu>
		</>
	);
};

export default Categories;
