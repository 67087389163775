import { TProduct } from 'Models/products/Product';
import { TProductInput } from 'Models/products/ProductInput';

export const makeProductInput = (
	product: TProduct
): Omit<TProductInput, 'treatments'> => {
	return {
		name: product.name,
		type: product.type,
		productSpec: {
			registrationNumber: product.productSpec?.registrationNumber || undefined,
			fracNumber: product.productSpec?.fracNumber || undefined,
			reEntryInterval: product.productSpec?.reEntryInterval || undefined,
			treatmentTime: product.productSpec?.treatmentTime || undefined,
			note: product.productSpec?.note || undefined,
			manufacturerName: product.productSpec?.manufacturerName || undefined,
			licenseExpirationDate: product.productSpec?.licenseExpirationDate,
			usageExpirationDate: product.productSpec?.usageExpirationDate,
			governmentRemarks: product.productSpec?.governmentRemarks || undefined,
		},
		ingredients: product.ingredients.map((ingredient) => ({
			name: ingredient?.name,
			active: ingredient?.active,
			amount: ingredient?.amount,
			unit: ingredient?.unit,
			organicAmount: ingredient?.organicAmount,
			organicUnit: ingredient?.organicUnit,
			registrationNumber: ingredient?.registrationNumber,
			groupName: ingredient?.groupName,
		})),
	};
};
