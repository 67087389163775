import { TTranslateFunction } from 'Features/localization';
import _uniq from 'lodash/uniq';

import translateProductCategory from 'Utils/translateProductCategory';

import { TJob } from './isJob';

const jobCategories = (job: Pick<TJob, 'jobFields'>, t: TTranslateFunction) =>
	_uniq(
		(job.jobFields || [])
			.map((field) => field.fieldVarieties || [])
			.flat()
			.map((variety) => variety.varietyCategory.localizedName)
	);

export default jobCategories;
