import { URLParamsType } from 'Models/history/isURLParams';
import { TPartnerVarietyInput } from 'Models/planner/PartnerVariety';

const getPartnerVarietyFromURL = (
	paramsFromURL: URLParamsType
): TPartnerVarietyInput | null => {
	const paramsFilters = paramsFromURL.filters;
	return paramsFilters.partnerVarietyName &&
		paramsFilters.partnerVarietyCategory &&
		paramsFilters.partnerVarietyName
		? {
				name: paramsFilters.partnerVarietyName,
				categoryId: paramsFilters.partnerVarietyCategory,
		  }
		: null;
};

export default getPartnerVarietyFromURL;
