import { TTranslateFunction } from 'Features/localization';
import harvestCategoryString from 'Models/harvest/harvestCategoryString';
import harvestCrewString from 'Models/harvest/harvestCrewString';
import harvestDateString from 'Models/harvest/harvestDateString';
import harvestFieldsNameString from 'Models/harvest/harvestFieldsNameString';
import harvestTeamMembersNameString from 'Models/harvest/harvestTeamMembersNameString';
import harvestVariety from 'Models/harvest/harvestVariety';
import harvestVolume from 'Models/harvest/harvestVolume';
import harvestWeightWithUnit from 'Models/harvest/harvestWeightWithUnit';

import { TPartnerHarvestTableData } from '../api/getPartnerHarvestsTableData';

function getPartnerHarvestRowData(
	harvest: TPartnerHarvestTableData,
	t: TTranslateFunction
) {
	return Object.values({
		date: harvestDateString(harvest),
		location: harvest.location.name,
		field: harvestFieldsNameString(harvest),
		category: harvestCategoryString(harvest),
		variety: harvestVariety(harvest),
		boxes: harvestVolume(harvest),
		weight: harvestWeightWithUnit(harvest),
		responsible: harvestCrewString(harvest),
		// TODO: Add teams when the backend is ready to serve them
		// directly through the Harvest type.
		teams: t('labels.notApplicable'),
		teamMembers: harvestTeamMembersNameString(harvest, true),
	});
}

export default getPartnerHarvestRowData;
