import isOneOf from 'vet/isOneOf';
import isInteger from 'vet/numbers/isInteger';
import isNumber from 'vet/numbers/isNumber';
import isShape from 'vet/objects/isShape';
import optional from 'vet/optional';
import isNotEmpty from 'vet/strings/isNotEmpty';
import isString from 'vet/strings/isString';
import ValidatorType from 'vet/types/ValidatorType';
import z from 'zod';

const _isFieldVarietyInput = isShape({
	_id: isString,
	name: isNotEmpty,
	varietyCategory: isShape({
		id: isString,
		name: isString,
		localizedName: isString,
		label: isString,
		categoryType: isString,
		categoryGroup: isString,
		faoCode: isNumber,
		siexCode: isNumber,
		swissCode: isNumber,
		swissLanguage: isString,
		germanyCode: isString,
		discriminator: isString,
	}),
	percentage: optional(isInteger),
	identifier: optional(isString),
	rows: optional(isInteger),
	length: optional(isOneOf(isNumber, isString)),
	width: optional(isOneOf(isNumber, isString)),
	sowDate: isString,
});

const isFieldVarietyInput: typeof _isFieldVarietyInput = _isFieldVarietyInput;

export type TFieldVarietyInput = ValidatorType<typeof isFieldVarietyInput>;

const _isSerializedFieldVarietyInput = isShape({
	name: isNotEmpty,
	varietyCategory: isShape({
		id: isString,
		name: isString,
		localizedName: isString,
		label: isString,
		categoryType: isString,
		categoryGroup: isString,
		faoCode: isNumber,
		siexCode: isNumber,
		swissCode: isNumber,
		swissLanguage: isString,
		germanyCode: isString,
		discriminator: isString,
	}),
	percentage: optional(isInteger),
	identifier: optional(isString),
	rows: optional(isInteger),
	length: optional(isNumber),
	width: optional(isNumber),
	sowDate: isString,
});

export const isSerializedFieldVarietyInput: typeof _isSerializedFieldVarietyInput =
	_isSerializedFieldVarietyInput;

export type TSanitizedFieldVarietyInput = ValidatorType<
	typeof isSerializedFieldVarietyInput
>;

export default isFieldVarietyInput;
