import React from 'react';

import { ApolloError, useQuery, useReactiveVar } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import toLocalDate from 'Models/dates/toLocalDate';
import { toDisplayString } from 'Models/units/transpileUnits';
import List from 'UI/display/List';
import Popover from 'UI/display/Popover';
import CloseIcon from 'UI/icons/Close';
import LoadingIcon from 'UI/icons/Loading';
import PlantIcon from 'UI/icons/Plant';
import ProductsIcon from 'UI/icons/Products';
import ShareIcon from 'UI/icons/Share';
import IconButton from 'UI/inputs/IconButton';
import Box from 'UI/layout/Box';
import format from 'date-fns/format';

import GET_PLANNER_JOB_DETAILS from '../../api/getPlannerJobDetails';
import { selectedJob, selectedJobId } from '../../state';
import { jobTypeColorMap } from '../PlannerChart/utils/getChartConfig';
import styles from '../styles.module.scss';
import DeleteJobModal from './DeleteJobModal';
import EditJobModal from './EditJobModal';
import ShareJobModal from './ShareJobModal';

function PlannerJobDetailsModal() {
	const anchorEl = useReactiveVar(selectedJob);
	const jobId = useReactiveVar(selectedJobId);
	const { t } = useTranslation();

	const { data, loading, error } = useQuery(GET_PLANNER_JOB_DETAILS, {
		skip: !jobId,
		variables: jobId
			? {
					plannerJobId: jobId,
			  }
			: undefined,
	});
	const job = data?.getPartnerPlannerJob;

	if (!jobId) {
		return null;
	}

	if (error) {
		throw new ApolloError(error);
	}

	const prefix = 'plannerPage.detailsModal';

	const handleClose = () => {
		selectedJob(null);
		selectedJobId(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? `${jobId}_details` : undefined;
	const dueDate = job?.dueDate ? toLocalDate(job.dueDate) : null;
	const isDueDatePast = dueDate && dueDate < new Date();

	return (
		<Popover
			id={id}
			anchorEl={anchorEl}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'center',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'center',
			}}
			open={open}
			onClose={handleClose}
			classes={{ paper: styles.paper }}
			elevation={12}
		>
			{loading ? <LoadingIcon /> : null}
			{job ? (
				<>
					<Box className={styles.modalHeader}>
						{!isDueDatePast ? <ShareJobModal job={job} /> : null}
						{!isDueDatePast || !job.sharedLocations ? (
							<>
								<EditJobModal job={job} />
								<DeleteJobModal job={job} />
							</>
						) : null}
						<IconButton onClick={handleClose}>
							<CloseIcon fontSize="small" />
						</IconButton>
					</Box>
					<List.List disablePadding>
						<List.Item dense>
							<List.ItemIcon>
								<div
									style={{
										width: '0.8rem',
										height: '0.8rem',
										borderRadius: '0.5rem',
										backgroundColor: jobTypeColorMap[job.jobDetails.jobType],
										margin: '-1.2rem 0.5rem 0.5rem 0.2rem',
									}}
								/>
							</List.ItemIcon>
							<List.ItemText
								sx={{ marginTop: '-0.25rem' }}
								disableTypography
								primary={
									<span style={{ fontSize: '1.25rem' }}>
										{t(`common.jobs.types.${job.jobDetails.jobType}`)}
									</span>
								}
								secondary={
									<p style={{ fontSize: '0.875rem' }}>
										{format(toLocalDate(job.dueDate), 'dd MMM, yyyy')}
									</p>
								}
							/>
						</List.Item>
						<List.Item dense>
							<List.ItemIcon>
								<PlantIcon fontSize="small" />
							</List.ItemIcon>
							<List.ItemText
								primary={t(`${prefix}.cropType`)}
								secondary={
									job.variety.varietyCategory.localizedName +
									' - ' +
									job.variety.name
								}
							/>
						</List.Item>
						<List.Item dense>
							<List.ItemIcon>
								<ProductsIcon fontSize="small" />
							</List.ItemIcon>
							<List.ItemText
								primary={t(`${prefix}.treatment`)}
								secondary={job.jobDetails.treatments.map((t) => (
									<>
										{t.originalProduct.name} - {t.amount}{' '}
										{toDisplayString(t.unit)}
										<br />
									</>
								))}
							/>
						</List.Item>
						<List.Item dense>
							<List.ItemIcon>
								<ShareIcon fontSize="small" />
							</List.ItemIcon>
							<List.ItemText
								primary={
									job.sharedLocations && job.sharedLocations.length > 0
										? t(`${prefix}.sharedWith`)
										: t(`${prefix}.notShared`)
								}
								secondary={job.sharedLocations?.map((e) => e.name).join(', ')}
							/>
						</List.Item>
					</List.List>
				</>
			) : null}
		</Popover>
	);
}

export default PlannerJobDetailsModal;
