import { gql, TypedDocumentNode } from '@apollo/client';
import TPageInput from 'Models/api/PageInput';
import { THarvestFilterInput } from 'Models/harvest/types/HarvestFilterInput';

import { THarvestExportQueryResponse } from '../types';

export type TGetHarvestExportDataResponse = {
	getHarvest: Omit<THarvestExportQueryResponse, 'totalPages'>;
};

type TGetHarvestExportInput = {
	locationId: string;
	pageInput?: TPageInput;
	filterBy?: Partial<THarvestFilterInput>;
};

const GET_HARVEST_EXPORT_DATA: TypedDocumentNode<
	TGetHarvestExportDataResponse,
	TGetHarvestExportInput
> = gql`
	query getHarvestExportData(
		$locationId: ID!
		$filterBy: HarvestFilterInput
		$pageInput: PageInput!
	) {
		getHarvest(
			locationId: $locationId
			filterBy: $filterBy
			pageInput: $pageInput
		) {
			totalElements
			content {
				id
				date
				fields {
					id
					name
				}
				varietyCategory {
					id
					name
					localizedName
					label
					categoryType
					categoryGroup
					faoCode
					siexCode
					swissCode
					swissLanguage
					germanyCode
					discriminator
				}
				variety
				countingUnit
				volume
				weight
				unit
				boxSize
				author {
					id
					firstName
					lastName
				}
				quality
				comment
				teamIds
				teamMembers {
					id
					fullName
				}
				teamLeads {
					id
					firstName
					lastName
				}
			}
		}
	}
`;

export default GET_HARVEST_EXPORT_DATA;
