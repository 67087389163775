import { makeVar } from '@apollo/client';
import { TProduct } from 'Models/products/Product';
import { TProductTreatmentInput } from 'Models/products/ProductTreatmentInput';
import { GovernmentProductTreatment } from 'Models/treatmentLibrary/governmentProductTreatment';
import { ZodError } from 'zod';

import { AddTreatmentModalStatus, TAddTreatmentsModalInput } from './types';

export const selectedGovernmentProduct = makeVar<TProduct | null>(null);

export const addTreatmentPageState = makeVar<TAddTreatmentsModalInput>({
	status: AddTreatmentModalStatus.SELECT_PRODUCT_SOURCE,
	editedCategoryId: undefined,
	input: {
		selectedGovernmentProduct: false,
		product: {
			treatments: [],
		},
	},
});

export const treatmentInputState =
	makeVar<TProductTreatmentInput | undefined>(undefined);

export const govTreatmentsInputState =
	makeVar<GovernmentProductTreatment | undefined>(undefined);

export const clearState = () => {
	addTreatmentPageState({
		status: AddTreatmentModalStatus.SELECT_PRODUCT_SOURCE,
		editedCategoryId: undefined,
		input: {
			selectedGovernmentProduct: false,
			product: {
				treatments: [],
			},
		},
	});
	treatmentInputState(undefined);
	treatmentInputValidationError(undefined);
	selectedGovernmentProduct(undefined);
	govTreatmentsInputState(undefined);
};

export const treatmentInputValidationError =
	makeVar<ZodError | undefined>(undefined);

export const getError = (
	key: keyof TProductTreatmentInput,
	errorObj?: ZodError
) => errorObj?.errors.find((e) => e.path.includes(key));
