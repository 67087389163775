import React, { FC, useCallback, useMemo, useState } from 'react';

import { useMutation, useReactiveVar } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { TProduct } from 'Models/products/Product';
import { TProductTreatment } from 'Models/products/ProductTreatment';
import { TProductTreatmentInput } from 'Models/products/ProductTreatmentInput';
import Accordion from 'UI/display/Accordion';
import Text from 'UI/display/Text';
import AddIcon from 'UI/icons/Add';
import Button from 'UI/inputs/Button';
import Grid from 'UI/layout/Grid';
import classnames from 'classnames';
import { enqueueSnackbar } from 'notistack';

import getTreatmentLibraryIdFromURL from 'Utils/getTreatmentLibraryIdFromURL';

import {
	addLibraryTreatmentInCache,
	CREATE_LIBRARY_TREATMENT,
	DELETE_LIBRARY_TREATMENT,
	DELETE_TREATMENT_LIBRARY_PRODUCT,
	removeDeletedLibraryTreatmentFromCache,
	removeDeletedTreatmentLibraryProduct,
} from '../../../api';
import styles from '../styles.module.scss';
import { AddTreatmentToProductModal } from './AddTreatmentToProductModal';
import {
	addTreatmentPageState,
	clearState,
	treatmentInputState,
} from './CreateTreatmentModal/state';
import {
	AddTreatmentModalStatus,
	TAddTreatmentsModalInput,
} from './CreateTreatmentModal/types';
import { EditProductModal } from './EditProductModal';
import { EditProductTreatmentModal } from './EditProductTreatmentsModal';
import { ProductTreatmentMenuButton } from './MenuButtons';
import { ProductMenuButton } from './MenuButtons';
import { makeTreatmentInput } from './utils';

type ProductListItemInterface = {
	product: TProduct;
	isProductSelected: boolean;
	handleToggleAccordionClick: (id: string) => void;
};
export const ProductListItem: FC<ProductListItemInterface> = ({
	product,
	isProductSelected,
	handleToggleAccordionClick,
}) => {
	const { t } = useTranslation();
	const state = useReactiveVar(addTreatmentPageState);
	const [isEditProductModalOpened, setEditProductModalOpened] = useState(false);
	const [
		isAddTreatmentToProductModalOpened,
		setAddTreatmentToProductModalOpened,
	] = useState(false);
	const [
		isEditProductTreatmentModalOpened,
		setEditProductTreatmentModalOpened,
	] = useState(false);

	const [isEditProductMenuOpened, setEditProductMenuOpened] = useState(false);
	const [
		editProductTreatmentMenuOpenedMap,
		setEditProductTreatmentMenuOpenedMap,
	] = useState({ ...product.treatments.map(({ id }) => ({ [id]: false })) });

	const [selectedTreatment, setSelectedTreatment] =
		useState<null | TProductTreatment>(null);

	const libraryId = getTreatmentLibraryIdFromURL();
	const [deleteTreatmentProduct] = useMutation(
		DELETE_TREATMENT_LIBRARY_PRODUCT,
		{
			update: removeDeletedTreatmentLibraryProduct,
		}
	);

	const [createProductTreatment] = useMutation(CREATE_LIBRARY_TREATMENT, {
		update: addLibraryTreatmentInCache,
	});

	const [deleteLibraryTreatment] = useMutation(DELETE_LIBRARY_TREATMENT, {});
	const categories = useMemo(
		() =>
			product.treatments.map((t) => t.varietyCategory.localizedName).join(', '),
		[product.treatments]
	);
	const productClassnames = classnames({
		[styles.product]: !isProductSelected,
		[styles.selectedProduct]: isProductSelected,
	});

	const handleAddTreatment = useCallback(
		(treatment: TProductTreatmentInput) => {
			void createProductTreatment({
				variables: {
					productId: product.id,
					libraryId,
					input: { ...treatment },
				},
			});
		},
		[createProductTreatment, libraryId, product.id]
	);

	const handleDeleteProduct = useCallback(
		(productId) => {
			if (productId) {
				void deleteTreatmentProduct({
					variables: { productId, libraryId },
				})
					.then(() => {
						enqueueSnackbar(
							t('treatmentLibrary.addTreatmentModal.deleteProduct.success'),
							{
								variant: 'success',
							}
						);
					})
					.catch(() => {
						enqueueSnackbar(
							t('treatmentLibrary.addTreatmentModal.deleteProduct.failure'),
							{
								variant: 'error',
							}
						);
					});
			}
		},
		[deleteTreatmentProduct, libraryId, t]
	);

	const handleDeleteLibraryTreatment = useCallback(
		(treatmentId) => {
			void deleteLibraryTreatment({
				variables: {
					libraryId,
					treatmentId,
					productId: product.id,
				},
				update: removeDeletedLibraryTreatmentFromCache,
			})
				.then(() => {
					enqueueSnackbar(
						t(
							'treatmentLibrary.addTreatmentModal.deleteProductTreatment.success'
						),
						{
							variant: 'success',
						}
					);
				})
				.catch(() => {
					enqueueSnackbar(
						t(
							'treatmentLibrary.addTreatmentModal.deleteProductTreatment.failure'
						),
						{
							variant: 'error',
						}
					);
				});
		},
		[deleteLibraryTreatment, libraryId, product.id, t]
	);

	const toggleEditProductMenu = useCallback(() => {
		setEditProductMenuOpened((prevState) => !prevState);
	}, []);

	const toggleEditProductTreatmentMenu = useCallback((id: string) => {
		setEditProductTreatmentMenuOpenedMap((prevState) => ({
			...prevState,
			[id]: !prevState[id],
		}));
	}, []);

	const onEditProductTreatmentMenu = useCallback(
		(treatment: TProductTreatment) => {
			treatmentInputState(makeTreatmentInput(treatment));
			addTreatmentPageState({
				...state,
				status: AddTreatmentModalStatus.TREATMENT_DETAILS,
				editedCategoryId: treatment.varietyCategory.id,
			} as TAddTreatmentsModalInput);
			setSelectedTreatment(treatment);
			setEditProductTreatmentModalOpened(true);
		},
		[state]
	);

	const toggleEditProductTreatmentModal = useCallback(() => {
		setEditProductTreatmentModalOpened((prevState) => !prevState);
		setEditProductTreatmentMenuOpenedMap((prevState) => ({
			...Object.keys(prevState).map((key) => ({ [prevState[key]]: false })),
		}));
		clearState();
	}, []);

	const toggleEditProductModal = useCallback(() => {
		setEditProductModalOpened((prevState) => {
			if (prevState) {
				setEditProductMenuOpened(false);
				clearState();
			} else {
				addTreatmentPageState({
					status: AddTreatmentModalStatus.SET_PRODUCT_DETAILS,
					editedCategoryId: undefined,
					input: {
						selectedGovernmentProduct:
							!!product?.governmentProductSpec?.governmentProductId,
						product: {
							...product,
							treatments: product.treatments.map(makeTreatmentInput),
						},
					},
				} as TAddTreatmentsModalInput);
			}
			return !prevState;
		});
	}, [product]);

	const onViewGovProduct = useCallback(() => {
		setEditProductModalOpened((prevState) => {
			if (prevState) {
				setEditProductMenuOpened(false);
				clearState();
			} else {
				addTreatmentPageState({
					status: AddTreatmentModalStatus.SET_PRODUCT_DETAILS,
					editedCategoryId: undefined,
					input: {
						selectedGovernmentProduct:
							!!product?.governmentProductSpec?.governmentProductId,
						product: {
							...product,
							treatments: product.treatments.map(makeTreatmentInput),
						},
					},
				} as TAddTreatmentsModalInput);
			}
			return !prevState;
		});
	}, [product]);

	const openAddTreatmentToProductModal = useCallback(() => {
		clearState();
		addTreatmentPageState({
			status: AddTreatmentModalStatus.SELECT_CROP_CATEGORY,
			editedCategoryId: undefined,
			input: {
				selectedGovernmentProduct:
					!!product?.governmentProductSpec?.governmentProductId,
				product: {
					...product,
					treatments: product.treatments.map(makeTreatmentInput),
				},
			},
		} as TAddTreatmentsModalInput);
		setAddTreatmentToProductModalOpened(true);
	}, [product]);

	const handleCloseAddTreatmentToProductModal = useCallback(() => {
		setAddTreatmentToProductModalOpened(false);
	}, []);

	const isOneTreatmentAdded = product.treatments.length === 1;

	return (
		<>
			<Accordion.Controlled
				sx={{
					'&::before': {
						display: 'none',
					},
				}}
				expanded={isProductSelected}
				onChange={() => handleToggleAccordionClick(product.id)}
				elevation={0}
				disableGutters
			>
				<Accordion.Header
					className={productClassnames}
					aria-controls="treatment-lib-collapsible-content"
				>
					<Grid container alignItems="center">
						<Grid item sm={isProductSelected ? 11 : 5}>
							<Text variant={isProductSelected ? 'label2' : 'label'}>
								{product.name}
							</Text>
							{isProductSelected && (
								<Text variant="secondaryBody3">{categories}</Text>
							)}
						</Grid>
						{isProductSelected ? (
							<Grid justifyContent="flex-end">
								<ProductMenuButton
									product={product}
									toggleMenu={toggleEditProductMenu}
									isMenuOpened={isEditProductMenuOpened}
									onDeleteProduct={handleDeleteProduct}
									onEditProduct={toggleEditProductModal}
									onViewProduct={onViewGovProduct}
								/>
							</Grid>
						) : (
							<Grid item sm={7}>
								<Text variant="secondaryBody2">{categories}</Text>
							</Grid>
						)}
					</Grid>
				</Accordion.Header>
				<Accordion.Content className={styles.productDetailsWrapper}>
					<Grid className={styles.productDetails}>
						{product.treatments.map((treatment, idx) => {
							const unitText = t(`apiConstants.productUnits.${treatment.unit}`);

							return (
								<Grid
									container
									className={styles.productTreatmentItem}
									key={treatment.id + idx}
								>
									<Grid item sm={11}>
										<Text variant="label2">
											{`${treatment.varietyCategory.localizedName} - ${treatment.amount} ${unitText}`}
										</Text>
										{treatment.treatmentCauses?.length ? (
											<Text variant="secondaryBody2">
												{treatment.treatmentCauses
													?.map(({ name }) => name)
													.join(', ')}
											</Text>
										) : null}
									</Grid>
									<Grid item sm={1}>
										<ProductTreatmentMenuButton
											treatment={treatment}
											isOneTreatmentAdded={isOneTreatmentAdded}
											toggleMenu={toggleEditProductTreatmentMenu}
											isMenuOpened={
												editProductTreatmentMenuOpenedMap[treatment.id]
											}
											onEditTreatment={onEditProductTreatmentMenu}
											onDeleteTreatment={handleDeleteLibraryTreatment}
										/>
									</Grid>
								</Grid>
							);
						})}
						<Button
							sx={{ marginTop: '0.75rem' }}
							variant="secondary"
							color="primary"
							startIcon={<AddIcon />}
							onClick={openAddTreatmentToProductModal}
						>
							{t('buttons.addTreatment')}
						</Button>
					</Grid>
				</Accordion.Content>
			</Accordion.Controlled>
			{isEditProductModalOpened && (
				<EditProductModal product={product} onClose={toggleEditProductModal} />
			)}
			{isEditProductTreatmentModalOpened && selectedTreatment && (
				<EditProductTreatmentModal
					productId={product.id}
					treatment={selectedTreatment}
					onClose={toggleEditProductTreatmentModal}
				/>
			)}
			{isAddTreatmentToProductModalOpened && (
				<AddTreatmentToProductModal
					onClose={handleCloseAddTreatmentToProductModal}
					onAddTreatment={handleAddTreatment}
				/>
			)}
		</>
	);
};
