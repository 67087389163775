import { gql, TypedDocumentNode } from '@apollo/client';
import TPageInput from 'Models/api/PageInput';
import { TLocation } from 'Models/locations/isLocation';
import { TPartnerTimesheet } from 'Models/partnershipCompany/PartnerTimesheet';
import { TTimesheetsFilterInput } from 'Models/timesheet/types/TimesheetsFilterInput';

export type TPartnerTimesheetResponse = {
	getPartnerTimesheets: {
		totalPages: number;
		totalElements: number;
		timesheets: {
			timesheet: TPartnerTimesheetTableData;
			location: TLocation;
		}[];
	};
};

export type TPartnerTimesheetTableData = Pick<
	TPartnerTimesheet,
	| 'id'
	| 'startTime'
	| 'location'
	| 'endTime'
	| 'team'
	| 'teamMember'
	| 'teamLead'
	| 'workLogs'
	| 'fields'
>;

export type TPartnerTimesheetRequest = {
	filterBy: {
		timesheetFilters?: Partial<
			Pick<
				TTimesheetsFilterInput,
				| 'categoryIds'
				| 'fieldIds'
				| 'teamId'
				| 'timeFrom'
				| 'timeTo'
				| 'varieties'
			>
		>;
		locationIds?: string[];
	};
	pageInput: TPageInput;
	hasPagination: boolean;
};

const GET_PARTNER_HOURS_TABLE_DATA: TypedDocumentNode<
	TPartnerTimesheetResponse,
	TPartnerTimesheetRequest
> = gql`
	query getPartnerHoursTableData(
		$filterBy: PartnerTimesheetsFilterInput
		$pageInput: PageInput
	) {
		getPartnerTimesheets(filterBy: $filterBy, pageInput: $pageInput) {
			totalPages
			totalElements
			timesheets {
				location {
					id
					name
				}
				timesheet {
					id
					team {
						id
						name
					}
					teamLead {
						id
						firstName
						lastName
					}
					teamMember {
						id
						fullName
					}
					startTime
					endTime
					fields {
						id
						name
					}
					workLogs {
						id
						workType
						duration
					}
					timesheetFieldHours {
						fieldId
						duration
						isLunchTimeSubtracted
					}
				}
			}
		}
	}
`;

export default GET_PARTNER_HOURS_TABLE_DATA;
