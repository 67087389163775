import React from 'react';

import { useMutation, useReactiveVar } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { TProductInput } from 'Models/products/ProductInput';
import { ProductTreatmentInputSchema } from 'Models/products/ProductTreatmentInput';
import { GRAM_PER_100_LITER, ProductUnits } from 'Models/units';
import Button from 'UI/inputs/Button';

import useSnackbarNotifications from 'Hooks/useSnackbarNotifications';
import useSpanishPartner from 'Hooks/useSpanishPartner';
import getTreatmentLibraryIdFromURL from 'Utils/getTreatmentLibraryIdFromURL';

import { CREATE_TREATMENT_LIBRARY_GOVERNMENT_TREATMENT } from '../../../../../api';
import { GET_PARTNER_TREATMENT_LIBRARY_PRODUCTS } from '../../../../../api/getTreatmentLibraryProducts';
import { makeGovernmentTreatmentInput } from '../../utils';
import {
	addTreatmentPageState,
	clearState,
	govTreatmentsInputState,
	treatmentInputState,
	treatmentInputValidationError,
} from '../state';
import {
	AddTreatmentModalStatus,
	isAddTreatmentModalInput,
	isSpanishProductDetails,
	TSelectCropCategoryInput,
} from '../types';

type TNextButtonProps = {
	onSave: () => void;
};

function NextButton(props: TNextButtonProps) {
	const { t } = useTranslation();
	const { onSave } = props;
	const libraryId = getTreatmentLibraryIdFromURL();
	const state = useReactiveVar(addTreatmentPageState);
	const treatmentInput = useReactiveVar(treatmentInputState);
	const govTreatmentsInput = useReactiveVar(govTreatmentsInputState);
	const { isSpanishPartner } = useSpanishPartner();
	const [
		createTreatmentLibraryGovernmentProduct,
		createTreatmentLibraryGovernmentProductResult,
	] = useMutation(CREATE_TREATMENT_LIBRARY_GOVERNMENT_TREATMENT);
	const { status } = state;

	const isDisabled =
		isSpanishPartner && status === AddTreatmentModalStatus.SET_PRODUCT_DETAILS
			? !isSpanishProductDetails(state)
			: [
					AddTreatmentModalStatus.SELECT_GOV_TREATMENTS,
					AddTreatmentModalStatus.GOV_TREATMENT_DETAILS,
			  ].includes(status)
			? !govTreatmentsInput
			: !isAddTreatmentModalInput(state);

	useSnackbarNotifications({
		mutationResult: createTreatmentLibraryGovernmentProductResult,
		messageSuccess: t(
			'treatmentLibrary.addTreatmentModal.createProductTreatment.success'
		),
		messageFailure: t(
			'treatmentLibrary.addTreatmentModal.createProductTreatment.failure'
		),
	});

	const nextHandler = () => {
		switch (status) {
			case AddTreatmentModalStatus.SET_PRODUCT_DETAILS: {
				addTreatmentPageState({
					...state,
					status: AddTreatmentModalStatus.SET_CROP_CATEGORIES,
				});
				break;
			}
			case AddTreatmentModalStatus.SELECT_CROP_CATEGORY: {
				if (state.editedCategoryId) {
					addTreatmentPageState({
						...state,
						status: AddTreatmentModalStatus.TREATMENT_DETAILS,
						input: {
							...state.input,
							product: {
								...state.input.product,
								treatments: [
									...(state.input.product.treatments || []),
									{
										categoryId: state.editedCategoryId,
										amount: 0,
										unit: ProductUnits[GRAM_PER_100_LITER],
									},
								],
							},
						},
					} as TSelectCropCategoryInput);
				}
				break;
			}
			case AddTreatmentModalStatus.TREATMENT_DETAILS: {
				if (treatmentInput) {
					//validate input
					const validationResult =
						ProductTreatmentInputSchema.safeParse(treatmentInput);

					if (validationResult.success) {
						const treatments =
							(state.input.product as TProductInput).treatments || [];

						const updatedTreatments = treatments.map((treatment) =>
							treatment.categoryId === state.editedCategoryId
								? treatmentInput
								: treatment
						);

						addTreatmentPageState({
							status: AddTreatmentModalStatus.SET_CROP_CATEGORIES,
							editedCategoryId: undefined,
							input: {
								...state.input,
								product: {
									...state.input.product,
									treatments: updatedTreatments,
								},
							},
						});
						treatmentInputState(undefined);
						treatmentInputValidationError(undefined);
					} else {
						treatmentInputValidationError(
							'error' in validationResult ? validationResult.error : undefined
						);
					}
				}
				break;
			}
			case AddTreatmentModalStatus.SET_CROP_CATEGORIES: {
				// product must have at least one treatment - see docs: Creating libraries pt. 6.
				if (state.input.product.treatments?.length) {
					onSave();
				}

				break;
			}
			case AddTreatmentModalStatus.SELECT_GOV_TREATMENTS: {
				onSave();
				break;
			}
			case AddTreatmentModalStatus.GOV_TREATMENT_DETAILS: {
				if (govTreatmentsInput?.id) {
					void createTreatmentLibraryGovernmentProduct({
						variables: {
							libraryId: libraryId,
							governmentTreatmentId: govTreatmentsInput?.id,
							input: makeGovernmentTreatmentInput(govTreatmentsInput),
						},
						refetchQueries: [GET_PARTNER_TREATMENT_LIBRARY_PRODUCTS],
					});
					onSave();
					clearState();
				}
			}
		}
	};

	return (
		<Button variant="outlined" onClick={nextHandler} disabled={isDisabled}>
			{[
				AddTreatmentModalStatus.SELECT_GOV_TREATMENTS,
				AddTreatmentModalStatus.SET_CROP_CATEGORIES,
				AddTreatmentModalStatus.GOV_TREATMENT_DETAILS,
			].includes(status)
				? t('buttons.save')
				: t('buttons.next')}
		</Button>
	);
}

export default NextButton;
