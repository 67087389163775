import React, { useRef, useState, useEffect } from 'react';

import { ApolloError, useQuery } from '@apollo/client';
import { Trans } from 'Features/localization/components/Trans';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import getUTCTime from 'Models/dates/getUTCTime';
import Chart, { TChartRef } from 'UI/display/Chart';
import Text from 'UI/display/Text';
import { format } from 'date-fns';
import { PointClickEventObject } from 'highcharts';

import useManageHistory from 'Hooks/useManageHistory';
import getPartnerVarietyFromURL from 'Utils/getPartnerVarietyFromURL';
import getSeasonParams from 'Utils/getSeasonParams';

import GET_PLANNER_CHART_DATA from '../../api/getPlannerChartData';
import { selectedJob, selectedJobId } from '../../state';
import styles from '../styles.module.scss';
import getChartConfig from './utils/getChartConfig';

const TODAY = Date.now();

const PlannerChart = () => {
	const { t } = useTranslation();
	const chartComponent = useRef<TChartRef>(null);

	const { paramsFromURL } = useManageHistory();
	const variety = getPartnerVarietyFromURL(paramsFromURL);
	// we assumed that seasonCutoffDate is 01-01 for PP
	const season = getSeasonParams(paramsFromURL.filters);
	const SEASON = {
		start: Date.UTC(Number(season.seasonName), 0, 1),
		end: Date.UTC(Number(season.seasonName), 11, 31),
		plotLine: TODAY,
	};

	const { data, loading, error } = useQuery(GET_PLANNER_CHART_DATA, {
		skip: !variety,
		variables: {
			filterBy: {
				variety: variety || undefined,
				dateFrom: format(new Date(season.from), 'yyyy-MM-dd'),
				dateTo: format(new Date(season.to), 'yyyy-MM-dd'),
			},
		},
	});

	if (error) {
		throw new ApolloError(error);
	}

	const showNoCropTypeChoosen = !variety;

	const onJobClick = (event: PointClickEventObject) => {
		selectedJob(event.point.graphic?.element);
		selectedJobId(event.point.options.custom?.jobId as string);
	};

	const chartConfig = getChartConfig(SEASON, t, onJobClick);
	const [showNoData, setShowNoData] = useState(false);
	const [chartOptions, setChartOptions] = useState(
		chartConfig.initialChartOptions
	);

	useEffect(() => {
		const chart = chartComponent?.current?.chart;

		if (chart) {
			if (loading) {
				chart.showLoading();
			}
			if (data) {
				const categories = chartConfig.categories;
				if (!data.getPartnerPlannerJobs.length) {
					chart.hideLoading();
					setShowNoData(true);
					setChartOptions({
						series: [],
						xAxis: chartConfig.initialChartOptions.xAxis,
					});
				} else {
					const chartData = chartConfig.chartRawData.map((rawData) => ({
						...rawData,
						data: data.getPartnerPlannerJobs
							.filter((job) => job.jobDetails.jobType === rawData.id)
							.map((job) => ({
								x: getUTCTime(job.dueDate),
								y: categories.indexOf(job.jobDetails.jobType),
								color:
									getUTCTime(job.dueDate) < TODAY
										? `${rawData.color}75`
										: rawData.color,
								custom: { jobId: job.id },
							})),
					}));
					chart.hideLoading();
					setShowNoData(false);

					setChartOptions({
						series: chartData,
						xAxis: chartConfig.initialChartOptions.xAxis,
					});
				}
			}
		}
	}, [chartComponent, data, loading, setShowNoData]);

	return (
		<div>
			{showNoCropTypeChoosen ? (
				<Text className={styles.noDataText} variant="label">
					<Trans i18nKey="plannerPage.plannerChart.noCropTypeInfo">
						Select which crop type you would like to add a job for. <br />
						Click the ‘Select crop type’ title on the top left.
					</Trans>
				</Text>
			) : showNoData ? (
				<Text className={styles.noDataText} variant="label">
					<Trans i18nKey="plannerPage.plannerChart.noDataInfo">
						You haven’t added anything to the planner yet. <br /> Click the ‘Add
						job’ button on the top right.
					</Trans>
				</Text>
			) : null}
			<Chart
				options={chartOptions}
				ref={chartComponent}
				containerProps={{
					className:
						showNoData || showNoCropTypeChoosen
							? styles.noDataChart
							: undefined,
				}}
			/>
		</div>
	);
};

export default PlannerChart;
