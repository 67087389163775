import { gql, TypedDocumentNode } from '@apollo/client';
import { TVarietyCategory } from 'Models/fields/isFieldVariety';

type TGetCategoriesResponse = {
	getCategories: TVarietyCategory[];
};

type TGetCategoriesRequest = {
	filterBy?: { discriminator: string };
};

export const GET_CATEGORIES: TypedDocumentNode<
	TGetCategoriesResponse,
	TGetCategoriesRequest
> = gql`
	query GetCategories($filterBy: CategoryFilterInput) {
		getCategories(filterBy: $filterBy) {
			id
			name
			localizedName
			label
			categoryType
			categoryGroup
			faoCode
			siexCode
			swissCode
			swissLanguage
			germanyCode
			discriminator
		}
	}
`;
