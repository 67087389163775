import React, { FC, useCallback } from 'react';

import { useReactiveVar } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import {
	ProductTreatmentInputSchema,
	TProductTreatmentInput,
} from 'Models/products/ProductTreatmentInput';
import Modal from 'UI/display/modal/dialog';
import Button from 'UI/inputs/Button';

import SelectCropCategory from '../CreateTreatmentModal/components/ModalContent/components/SelectCropCategory';
import TreatmentDetails from '../CreateTreatmentModal/components/ModalContent/components/TreatmentDetails';
import {
	addTreatmentPageState,
	clearState,
	treatmentInputState,
	treatmentInputValidationError,
} from '../CreateTreatmentModal/state';
import {
	AddTreatmentModalStatus,
	TSelectCropCategoryInput,
} from '../CreateTreatmentModal/types';

interface AddTreatmentToProductModalProps {
	onClose: () => void;
	onAddTreatment: (treatment: TProductTreatmentInput) => void;
}

export const AddTreatmentToProductModal: FC<AddTreatmentToProductModalProps> =
	({ onClose, onAddTreatment }) => {
		const { t } = useTranslation();
		const state = useReactiveVar(addTreatmentPageState);
		const treatmentInput = useReactiveVar(treatmentInputState);
		const handleSaveClick = useCallback(() => {
			if (state.status === AddTreatmentModalStatus.SELECT_CROP_CATEGORY) {
				addTreatmentPageState({
					...state,
					status: AddTreatmentModalStatus.TREATMENT_DETAILS,
				} as TSelectCropCategoryInput);
			} else {
				const validationResult =
					ProductTreatmentInputSchema.safeParse(treatmentInput);
				if (!validationResult.success || !treatmentInput) {
					treatmentInputValidationError(
						'error' in validationResult ? validationResult.error : undefined
					);
					return;
				}
				onAddTreatment(treatmentInput);
				clearState();
				onClose();
			}
		}, [onAddTreatment, onClose, state, treatmentInput]);

		return (
			<Modal.UncontrolledModal toggleOpen={onClose} open={true}>
				<Modal.DialogTitle
					title={t('buttons.editProductLibrary')}
					closeIconHandler={onClose}
				/>
				<Modal.DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
					{state.status === AddTreatmentModalStatus.SELECT_CROP_CATEGORY && (
						<SelectCropCategory />
					)}
					{state.status === AddTreatmentModalStatus.TREATMENT_DETAILS && (
						<TreatmentDetails />
					)}
				</Modal.DialogContent>
				<Modal.DialogActions>
					<Button variant="text" onClick={onClose}>
						{t('buttons.cancel')}
					</Button>
					<Button variant="outlined" onClick={handleSaveClick} disabled={false}>
						{t(
							state.status === AddTreatmentModalStatus.SELECT_CROP_CATEGORY
								? 'buttons.next'
								: 'buttons.save'
						)}
					</Button>
				</Modal.DialogActions>
			</Modal.UncontrolledModal>
		);
	};
