import React from 'react';

import LogsTableHeaderValues from 'Models/harvest/harvestLogsTableData';
import Table from 'UI/display/Table';

import { detailsModalData, isDetailsModalOpen } from '../../../state';
import { THarvestTableData } from '../../../types';
import getHarvestLogsRowData from '../../../utils/getHarvestLogsRowData';

type THarvestLogsTableRowProps = {
	harvest: THarvestTableData;
};

const TableRow = (props: THarvestLogsTableRowProps) => {
	const { harvest } = props;

	function onRowClick() {
		isDetailsModalOpen(true);
		detailsModalData(harvest);
	}

	return (
		<Table.Row hover sx={{ cursor: 'pointer' }} onClick={onRowClick}>
			{getHarvestLogsRowData(harvest, true).map((cell, index) => (
				<Table.Cell key={`cell-${LogsTableHeaderValues[index] || index}`}>
					{cell}
				</Table.Cell>
			))}
		</Table.Row>
	);
};

export default TableRow;
