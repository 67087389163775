import _sortBy from 'lodash/sortBy';
import _uniq from 'lodash/uniq';
import _uniqBy from 'lodash/uniqBy';

import { TFieldCategory } from '../../common/api/getFields';

export const getCategoriesFromFields = (
	getFieldsParsedResponse?: TFieldCategory[]
) =>
	_sortBy(
		_uniq(
			getFieldsParsedResponse?.flatMap((field) =>
				field.varieties.map((variety) => variety.varietyCategory.localizedName)
			)
		),
		(cat) => cat.toLocaleLowerCase()
	);

export const getCategoriesWithIdsFromFields = (
	getFieldsParsedResponse?: TFieldCategory[]
) =>
	_sortBy(
		_uniqBy(
			getFieldsParsedResponse?.flatMap((field) =>
				field.varieties.map(({ varietyCategory: { localizedName, id } }) => ({
					name: localizedName,
					id,
				}))
			),
			'id'
		),
		(cat) => cat.name.toLocaleLowerCase()
	);
