import { gql, TypedDocumentNode } from '@apollo/client';
import { TPartnerVariety } from 'Models/planner/PartnerVariety';

export type TPartnerVarietiesResponse = TPartnerVariety[];

const GET_PARTNER_VARIETIES_DATA: TypedDocumentNode<
	{
		getPartnerVarieties: TPartnerVarietiesResponse;
	},
	null
> = gql`
	query getPartnerVarieties {
		getPartnerVarieties {
			name
			varietyCategory {
				id
				name
				localizedName
				label
				categoryType
				categoryGroup
				faoCode
				siexCode
				swissCode
				swissLanguage
				germanyCode
				discriminator
			}
		}
	}
`;

export default GET_PARTNER_VARIETIES_DATA;
