import React from 'react';

import { ApolloError, useQuery } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import Countries from 'Models/locations/LocationCountries';
import locationCountry from 'Models/locations/locationCountry';

import useCurrentLocation from 'Hooks/useCurrentLocation';
import useLocationSubscriptions from 'Hooks/useLocationSubscriptions';
import useLocations from 'Hooks/useLocations';
import useProfile from 'Hooks/useProfile';
import getSimplifiedCountryCode from 'Utils/getSimplifiedCountryCode';

import LoadingContent from '../../../components/LoadingContent';
import StripeProductCard from '../../../components/stripe/StripeProductCard';
import StripeProvider from '../../../features/stripe/StripeProvider';
import GET_PAYMENT_PRODUCTS from '../api/getPaymentProducts';
import getAvailableProducts from '../utils/getAvailableProducts';
import AdvisoryOrganizationInfo from './AdvisoryOrganizationInfo';
import EnterpriseFarmsInfo from './EnterpriseFarmsInfo';
import NoCreditCardMessage from './NoCreditCardMessage';

const StorePageBody = () => {
	const location = useCurrentLocation();
	const { locations } = useLocations();
	const { i18n } = useTranslation();
	const { profile, loading: isProfileLoading } = useProfile();
	const { locationSubscriptions, loading } = useLocationSubscriptions();
	const productsQuery = useQuery(GET_PAYMENT_PRODUCTS, {
		skip: !location,
		variables: location?.id
			? {
					locationId: location?.id,
			  }
			: undefined,
	});

	const countryCode = getSimplifiedCountryCode(i18n.language);
	if (productsQuery.data && locationSubscriptions) {
		const availableProducts = getAvailableProducts({
			//TODO Replace filter product inventory subscription to backend
			products: productsQuery.data.getPaymentProducts.filter(
				(product) =>
					!['prod_OwCDlEXOQjqJAO', 'prod_OSdR5ArZMVEHp4'].includes(product.id)
			),
			subscriptions: locationSubscriptions,
			locationType: location?.type,
			countryCode,
		});

		const showPartnerPortalOptions =
			profile &&
			locations &&
			locations.filter((location) => location?.owner?.id === profile.id)
				.length > 1;

		const displayNoCreditCardMessage =
			location && locationCountry(location) === Countries.DE;

		return (
			<>
				{displayNoCreditCardMessage ? <NoCreditCardMessage /> : null}
				<StripeProvider>
					{availableProducts.map((product) => (
						<StripeProductCard
							key={product.id}
							stripeProduct={product}
							stripePrices={product.prices || null}
							isPurchased={product.isPurchased}
						/>
					))}
				</StripeProvider>
				{showPartnerPortalOptions ? <EnterpriseFarmsInfo /> : null}
				{showPartnerPortalOptions ? <AdvisoryOrganizationInfo /> : null}
			</>
		);
	}

	if (productsQuery.error) {
		throw new ApolloError(productsQuery.error);
	}

	if (productsQuery.loading || loading || isProfileLoading) {
		return <LoadingContent />;
	}

	return null;
};

export default StorePageBody;
