import React, { useEffect, useState } from 'react';

import { useMutation } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import locationUnit from 'Models/locations/locationUnit';
import Avatar from 'UI/display/Avatar';
import Text from 'UI/display/Text';
import LoadingIcon from 'UI/icons/Loading';
import Button from 'UI/inputs/Button';
import TextField from 'UI/inputs/TextField';
import Box from 'UI/layout/Box';
import Grid from 'UI/layout/Grid';
import Card from 'UI/surfaces/Card';
import CardContent from 'UI/surfaces/Card/CardContent';

import useCurrentLocation from 'Hooks/useCurrentLocation';
import useSnackbarNotifications from 'Hooks/useSnackbarNotifications';
import containsNumber from 'Utils/containsNumber';

import CountryDropdown from '../../../../components/CountryDropdown';
import UPDATE_LOCATION_MUTATION from '../api/updateLocationInfo';
import { ResetPassword } from './ResetPassword';
import styles from './styles.module.scss';

const DetailsForm = () => {
	const { t } = useTranslation();
	const location = useCurrentLocation();
	const [updateInfo, updateTask] = useMutation(UPDATE_LOCATION_MUTATION);
	// input state
	const [isInputError, setInputError] = useState(false);
	const [name, setName] = useState(location?.name);
	const [businessName, setBusinessName] = useState(location?.businessName);
	const [address, setAddress] = useState(location?.address);
	const [zip, setZip] = useState(location?.zip);
	const [city, setCity] = useState(location?.city);

	useEffect(() => {
		if (location) {
			setName(location.name);
			setAddress(location.address);
			setZip(location.zip);
			setCity(location.city);
		}
	}, [location]);

	useSnackbarNotifications({
		mutationResult: updateTask,
		messageSuccess: t('info.editFarmSuccess'),
		messageFailure: t('errors.editFarmFailed'),
	});

	const onSaveLocation = () => {
		if (location && name && !containsNumber(city)) {
			const input = {
				name,
				businessName,
				city,
				zip,
				address,
			};

			void updateInfo({
				variables: { input, id: location?.id },
				refetchQueries: ['location'],
			});

			setInputError(false);
		} else {
			setInputError(true);
		}
	};

	const saveButton = updateTask.loading ? (
		<Box display="flex">
			<Box alignSelf="flex-end">
				<LoadingIcon />
			</Box>
			<Button disableElevation disabled>
				{t('buttons.saveChanges')}
			</Button>
		</Box>
	) : (
		<Button disableElevation onClick={onSaveLocation}>
			{t('buttons.saveChanges')}
		</Button>
	);

	return (
		<Card>
			<CardContent>
				<Text variant="h3" component="p">
					{t('farmSettingsPage.details.title')}
				</Text>
				<Box paddingTop={3}>
					<Grid container spacing={3}>
						<Grid xs={9} container item spacing={3}>
							<Grid item xs={12}>
								<TextField
									disabled={updateTask.loading}
									value={name || ''}
									label={t('labels.farmName')}
									onChange={(val) => setName(val)}
									helperText={
										isInputError && !name
											? `${t('errors.requiredField.message')}`
											: undefined
									}
									error={isInputError && !name}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									disabled={updateTask.loading}
									value={businessName || ''}
									label={t('labels.businessName')}
									onChange={(val) => setBusinessName(val)}
								/>
							</Grid>
						</Grid>
						<Grid item className={styles.autoMargin}>
							<Avatar size="xxl" variant="rounded" alt={name || undefined} />
						</Grid>
						<Grid item xs={12}>
							<CountryDropdown
								name="country"
								disabled={true}
								value={location?.country || ''}
								label={t('labels.country')}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								disabled={updateTask.loading}
								value={address || ''}
								label={t('labels.address')}
								onChange={(val) => setAddress(val)}
							/>
						</Grid>
						<Grid item xs={3}>
							<TextField
								disabled={updateTask.loading}
								value={zip || ''}
								label={t('labels.zip')}
								onChange={(val) => setZip(val)}
							/>
						</Grid>
						<Grid item xs={9}>
							<TextField
								disabled={updateTask.loading}
								value={city || ''}
								label={t('labels.city')}
								onChange={(val) => setCity(val)}
								error={isInputError}
								helperText={
									isInputError && city && containsNumber(city)
										? `${t('errors.inputContainsNumber')}`
										: undefined
								}
							/>
						</Grid>
						<Grid item xs={6}>
							<TextField
								disabled
								value={location ? locationUnit(location) : ''}
								label={t('labels.areaUnit')}
							/>
						</Grid>
						<Grid item xs={6}>
							<TextField
								disabled
								value={location?.seasonCutoffDate || '-'}
								label={t('labels.seasonCutoffDate')}
							/>
						</Grid>
						<ResetPassword />
						<Grid container item justifyContent="flex-end">
							<Grid item>{saveButton}</Grid>
						</Grid>
					</Grid>
				</Box>
			</CardContent>
		</Card>
	);
};

export default DetailsForm;
