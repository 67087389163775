import {
	ReportType,
	TReportBreakdown,
	TReportFormat,
} from 'Models/exports/Report';
import { TJobStatuses } from 'Models/jobs/JobStatuses';
import { z } from 'zod';

export interface ReportFormInterface {
	dateFrom: string;
	dateTo: string;
	status: TJobStatuses[];
	categoryIds: string[];
	fields: string[];
	format: TReportFormat;
	teamId: string[];
	teamMemberIds: string[];
	varieties: string[];
	quality: string[];
	breakdown: TReportBreakdown;
	products: string[];
}

export const CreateReportInputSchema = z.object({
	dateFrom: z.string().min(1),
	dateTo: z.string().min(1),
	status: z.array(z.string()).min(1),
	categories: z.array(z.string()).optional(),
	teamId: z.array(z.string()).optional(),
	teamMemberIds: z.array(z.string()).optional(),
	varieties: z.array(z.string()).optional(),
	fields: z.array(z.string()).optional(),
	breakdown: z.string(),
});

export enum CREATE_REPORT_TAB_STATE {
	form = 'form',
	summary = 'summary',
}

export type TCreateReportType =
	| ReportType.SPRAYING
	| ReportType.FERTILIZER
	| ReportType.HARVEST
	| ReportType.TIMESHEET
	| ReportType.PRODUCT_INVENTORY;
