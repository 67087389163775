export enum ReportType {
	SPRAYING = 'SPRAYING',
	FERTILIZER = 'FERTILIZER',
	HARVEST = 'HARVEST',
	PRODUCT_INVENTORY = 'PRODUCT_INVENTORY',
	TIMESHEET = 'TIMESHEET',
	FIELDS = 'FIELDS',
}

export type TReportType = keyof typeof ReportType;

export const ReportFormat = {
	PDF: 'PDF',
	XLSX: 'XLSX',
} as const;

export type TReportFormat = keyof typeof ReportFormat;

export const ReportBreakdown = {
	FIELD: 'FIELD',
	JOB: 'JOB',
} as const;

export type TReportBreakdown = keyof typeof ReportBreakdown;

export type TReportParams = {
	dateFrom: string;
	dateTo: string;
	categoryIds?: string[];
	fields?: string[];
	teamId?: string[];
	status?: string[];
	teamMemberIds?: string[];
	varieties?: string[];
	format?: string;
	quality?: number[];
	products?: string[];
	breakdown?: TReportBreakdown;
};
