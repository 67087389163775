import { gql, TypedDocumentNode } from '@apollo/client';

import {
	TPartnerHarvestExportFileResponse,
	TPartnerHarvestFilterInput,
} from '../types';

const GET_PARTNER_HARVEST_EXPORT_DATA: TypedDocumentNode<
	TPartnerHarvestExportFileResponse,
	TPartnerHarvestFilterInput
> = gql`
	query getPartnerHarvestsExportData(
		$filterBy: PartnerHarvestFilterInput
		$pageInput: PageInput
	) {
		getPartnerHarvests(filterBy: $filterBy, pageInput: $pageInput) {
			totalElements
			totalPages
			content {
				harvest {
					id
					date
					fields {
						id
						name
						areaSize
					}
					category
					comment
					variety
					varietyCategory {
						id
						name
						localizedName
						label
						categoryType
						categoryGroup
						faoCode
						siexCode
						swissCode
						swissLanguage
						germanyCode
						discriminator
					}
					author {
						id
						firstName
						lastName
					}
					volume
					boxSize
					boxName
					quality
					countingUnit
					weight
					unit
					teamIds
					teamMembers {
						id
						fullName
					}
				}
				location {
					id
					name
				}
			}
		}
	}
`;

export default GET_PARTNER_HARVEST_EXPORT_DATA;
