import React, { ReactElement, useEffect, useState } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { TFieldType, FieldTypes } from 'Models/fields/isFieldType';
import isFieldVarietyInput, {
	TFieldVarietyInput,
} from 'Models/fields/isFieldVarietyInput';
import AddIcon from 'UI/icons/Add';
import Button from 'UI/inputs/Button';
import Box from 'UI/layout/Box';
import isArrayOf from 'vet/arrays/isArrayOf';

import Category from '../Category';
import GreenHouseVarieties from './components/GreenHouseVarieties';
import PercentageVarieties from './components/PercentageVarieties';
import { addVariety } from './utils';

export type TVarietyListItem = {
	id: string;
	variety: TFieldVarietyInput;
};

type TVarietyProps = {
	fieldType: TFieldType;
	varieties: TFieldVarietyInput[];
	setVarieties: (varieties: TFieldVarietyInput[]) => void;
};

type TAddMoreButtonProps = {
	varieties: TFieldVarietyInput[];
	setVarieties: (varieties: TFieldVarietyInput[]) => void;
	category?: string;
};

const AddMoreButton = (props: TAddMoreButtonProps) => {
	const { varieties, setVarieties, category } = props;
	const { t } = useTranslation();

	const invalidVarieties = !isArrayOf(isFieldVarietyInput)(varieties);

	return (
		<Box>
			<Button
				variant="secondary"
				color="primary"
				disabled={invalidVarieties}
				startIcon={<AddIcon />}
				onClick={() => addVariety(varieties, setVarieties, category)}
			>
				{t('buttons.addOneMore')}
			</Button>
		</Box>
	);
};

const Varieties = (props: TVarietyProps): ReactElement => {
	const { fieldType, varieties, setVarieties } = props;
	const [category, setCategory] = useState('');

	function setExistingCategory() {
		const previouslyChosenCategory =
			(varieties.length && varieties[0].varietyCategory.localizedName) || '';

		setCategory(previouslyChosenCategory);
	}

	useEffect(setExistingCategory, [fieldType]);

	function handleChangeCategory(category: string) {
		setCategory(category);
		setVarieties(varieties.map((variety) => ({ ...variety, category })));
	}

	return (
		<>
			<Category category={category} onChangeCategory={handleChangeCategory} />
			{fieldType === FieldTypes.GREEN_HOUSE ? (
				<GreenHouseVarieties
					varieties={varieties}
					setVarieties={setVarieties}
				/>
			) : (
				<PercentageVarieties
					varieties={varieties}
					setVarieties={setVarieties}
				/>
			)}
			<AddMoreButton
				varieties={varieties}
				setVarieties={setVarieties}
				category={category}
			/>
		</>
	);
};

export default Varieties;
