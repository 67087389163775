import isString from 'vet/strings/isString';

import { TFieldInput } from './isFieldInput';

function fieldInputCategory(fieldInput: TFieldInput): string {
	const categories = fieldInput?.varieties
		?.map(({ varietyCategory }) => varietyCategory.localizedName)
		.filter((localizedName) => isString(localizedName));

	if (categories && categories.length > 0) {
		return categories.join(', ');
	}

	return '-';
}

export default fieldInputCategory;
