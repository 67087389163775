import React, { useState, useEffect } from 'react';

import { useMutation, useReactiveVar } from '@apollo/client';
import { TTranslateFunction } from 'Features/localization';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import useParams from 'Features/router/hooks/useParams';
import { isProductInput } from 'Models/products/ProductInput';
import Modal from 'UI/display/modal/dialog';
import AddIcon from 'UI/icons/Add';
import Button from 'UI/inputs/Button';
import Box from 'UI/layout/Box';
import { FarmableColors } from 'UI/theme/Colors';

import useSnackbarNotifications from 'Hooks/useSnackbarNotifications';

import { useGetCategoryNameMap } from '../../../../PartnerTreatmentLibraryPage.hooks';
import {
	CREATE_TREATMENT_LIBRARY_PRODUCT,
	addCreatedTreatmentLibraryProductInCache,
} from '../../../../api';
import { sanitizeProductInput } from '../utils';
import ModalContent from './components/ModalContent';
import NextButton from './components/NextButton';
import PreviousButton from './components/PreviousButton';
import { addTreatmentPageState, clearState } from './state';
import { AddTreatmentModalStatus, TAddTreatmentsModalInput } from './types';

function AddTreatmentModal() {
	const { t } = useTranslation();
	const { libraryID } = useParams();
	const [open, setOpen] = useState(false);
	const state = useReactiveVar(addTreatmentPageState);
	const [createTreatmentLibraryProduct, createTreatmentLibraryProductResult] =
		useMutation(CREATE_TREATMENT_LIBRARY_PRODUCT);
	const categoriesNamesMap = useGetCategoryNameMap();

	const toggleOpen = () => setOpen(!open);

	useEffect(() => {
		if (open) {
			addTreatmentPageState({
				...state,
				editedCategoryId: undefined,
				status: AddTreatmentModalStatus.SELECT_PRODUCT_SOURCE,
			} as TAddTreatmentsModalInput);
		} else {
			clearState();
		}
	}, [open]);

	useSnackbarNotifications({
		mutationResult: createTreatmentLibraryProductResult,
		messageSuccess: t(
			'treatmentLibrary.addTreatmentModal.createProductTreatment.success'
		),
		messageFailure: t(
			'treatmentLibrary.addTreatmentModal.createProductTreatment.failure'
		),
	});

	const onSaveAction = () => {
		const productInput = sanitizeProductInput(state.input.product);

		if (
			state.status === AddTreatmentModalStatus.SET_CROP_CATEGORIES &&
			isProductInput(productInput)
		) {
			void createTreatmentLibraryProduct({
				variables: {
					libraryId: libraryID,
					input: productInput,
				},
				update: addCreatedTreatmentLibraryProductInCache,
				onCompleted() {
					setOpen(false);
					clearState();
				},
			});
		} else {
			setOpen(false);
		}
	};

	const getModalTitle = (
		state: TAddTreatmentsModalInput,
		t: TTranslateFunction
	) => {
		switch (state.status) {
			case AddTreatmentModalStatus.SELECT_CROP_CATEGORY:
				return t('labels.addCrop');
			case AddTreatmentModalStatus.TREATMENT_DETAILS:
				return categoriesNamesMap[state.editedCategoryId || ''];
			case AddTreatmentModalStatus.SELECT_GOV_TREATMENTS:
				return t('buttons.addTreatment');
			default:
				return t('treatmentLibrary.modal.newTreatment');
		}
	};

	return (
		<>
			<Button
				variant="secondary"
				color="primary"
				startIcon={<AddIcon />}
				onClick={toggleOpen}
			>
				{t('buttons.addTreatment')}
			</Button>
			<Modal.UncontrolledModal toggleOpen={toggleOpen} open={open}>
				<Modal.DialogTitle
					title={getModalTitle(state, t)}
					closeIconHandler={toggleOpen}
				/>
				<Modal.DialogContent
					sx={
						state.status === 'SELECT_CROP_CATEGORY'
							? { display: 'flex', flexDirection: 'column' }
							: undefined
					}
				>
					<ModalContent />
				</Modal.DialogContent>
				<Modal.DialogActions
					sx={
						state.status === 'SELECT_CROP_CATEGORY'
							? { borderTop: `1px solid ${FarmableColors.DIVIDER}` }
							: undefined
					}
				>
					<Box display="flex" justifyContent="space-between" width="100%">
						<PreviousButton
							closeModalHandler={() => {
								toggleOpen();
								clearState();
							}}
						/>
						<NextButton onSave={onSaveAction} />
					</Box>
				</Modal.DialogActions>
			</Modal.UncontrolledModal>
		</>
	);
}

export default AddTreatmentModal;
