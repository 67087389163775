import { gql, TypedDocumentNode } from '@apollo/client';
import TPageInput from 'Models/api/PageInput';
import { THarvestBatch } from 'Models/harvestBatch/isHarvestBatch';

type TGetHarvestBatchesResponse = {
	getHarvestBatches: {
		totalPages: number;
		totalElements: number;
		content: THarvestBatch[];
	};
};

type TGetHarvestBatchesRequest = {
	locationId: string;
	filterBy?: {
		dateFrom?: string;
		dateTo?: string;
		onlySoldBatches?: boolean;
		varieties?: string[];
	};
	pageInput: TPageInput;
};

const GET_HARVEST_BATCHES: TypedDocumentNode<
	TGetHarvestBatchesResponse,
	TGetHarvestBatchesRequest
> = gql`
	query getHarvestBatches(
		$locationId: ID!
		$filterBy: HarvestBatchFilterInput
		$pageInput: PageInput!
	) {
		getHarvestBatches(
			locationId: $locationId
			filterBy: $filterBy
			pageInput: $pageInput
		) {
			totalPages
			totalElements
			content {
				id
				customId
				date
				variety
				field {
					id
					name
					areaSize
				}
				comment
				totalVolume
				soldVolume
				wasteVolume
				status
			}
		}
	}
`;

export default GET_HARVEST_BATCHES;
