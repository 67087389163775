import React from 'react';

import { ApolloError, useQuery } from '@apollo/client';
import Box from 'UI/layout/Box';

import getTreatmentLibraryIdFromURL from 'Utils/getTreatmentLibraryIdFromURL';

import ErrorBoundary from '../../../components/ErrorBoundary';
import LoadingContent from '../../../components/LoadingContent';
import { GET_PARTNER_TREATMENT_LIBRARY } from './api/getTreatmentLibrary';
import PageBody from './components/PageBody';
import PageHeader from './components/PageHeader';

function PartnerTreatmentLibraryPage() {
	const libraryId = getTreatmentLibraryIdFromURL();

	const { data, loading, error } = useQuery(GET_PARTNER_TREATMENT_LIBRARY, {
		variables: {
			libraryId,
		},
	});

	if (data) {
		const libraryData = data.getTreatmentLibrary;
		return (
			<ErrorBoundary>
				<PageHeader companyName={libraryData.name} />
				<Box paddingBottom="2rem">
					<PageBody />
				</Box>
			</ErrorBoundary>
		);
	}

	if (loading) {
		return <LoadingContent />;
	}

	if (error) {
		throw new ApolloError(error);
	}

	return null;
}

export default PartnerTreatmentLibraryPage;
