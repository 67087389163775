import React from 'react';

import { ApolloError, useQuery } from '@apollo/client';
import Table from 'UI/display/Table';
import NoDataTableContainer from 'UI/display/Table/NoDataTableContainer';
import LoadingSpinner from 'UI/icons/LoadingSpinner';

import useManageHistory from 'Hooks/useManageHistory';

import NoLogsStatement from '../../common/components/NoLogsStatement';
import GET_JOBS_TABLE_DATA from '../api/getJobsTableData';
import getQueryVariables from '../utils/getQueryVariables';
import JobLogsTableRow from './TableRow';

const TableBody = () => {
	const { paramsFromURL: searchParams } = useManageHistory();
	const { data, loading, error } = useQuery(GET_JOBS_TABLE_DATA, {
		variables: getQueryVariables(searchParams),
	});

	if (loading) {
		return (
			<NoDataTableContainer>
				<LoadingSpinner width={80} height={80} />
			</NoDataTableContainer>
		);
	}

	if (error) {
		throw new ApolloError(error);
	}

	if (!data?.getJobs.content?.length) {
		return (
			<NoDataTableContainer>
				<NoLogsStatement logsType="Jobs" />
			</NoDataTableContainer>
		);
	}

	return (
		<Table.Body>
			{data.getJobs.content.map((job) => (
				<JobLogsTableRow key={job.id} job={job} />
			))}
		</Table.Body>
	);
};

export default TableBody;
