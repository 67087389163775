import { TTranslateFunction } from 'Features/localization';
import { TField } from 'Models/fields/isField';
import { TVariety } from 'Models/fields/isVariety';
import { AreaUnits } from 'Models/units';
import _uniq from 'lodash/uniq';

import isString from 'Utils/isString';

import sumOtherVarieties from '../components/CropSummary/utils/sumOtherVarieties';

const MAX_VAR_NUMBER = 5;

export type TCropSummaryCategory = {
	category: string;
	areaSize: number;
	areaUnit: AreaUnits;
	fieldIds: string[];
};

function getSummarySectionData(
	fields: Pick<TField, 'id' | 'areaSize' | 'varieties'>[],
	translateFunction: TTranslateFunction,
	locationAreaUnit?: AreaUnits | undefined | null
): TCropSummaryCategory[] {
	const areaUnit = locationAreaUnit || AreaUnits.HECTARE;
	const categoryDict = {} as Record<string, TCropSummaryCategory>;
	const otherCategoryName = translateFunction('productCategories.other');

	const aggregateVarietyInDict = (
		{ id, areaSize }: Pick<TField, 'id' | 'areaSize'>,
		{
			varietyCategory,
			percentage,
		}: Pick<TVariety, 'varietyCategory' | 'percentage'>
	) => {
		if (!isString(varietyCategory.localizedName) || !areaSize) {
			return;
		}

		// in some cases field varieties have 0 percentage,
		// when there is only one variety, we just assume its 100,
		// otherwise we probably should estimate the area (not implemented)
		const varietyShare = (percentage || 100) / 100;

		if (varietyCategory.localizedName in categoryDict) {
			const categorySummary = categoryDict[varietyCategory.localizedName];
			const uniqFieldIdsArray = _uniq([...categorySummary.fieldIds, id]);

			const updatedVarietyEntry = {
				...categorySummary,
				areaSize: categorySummary.areaSize + varietyShare * areaSize,
				fieldIds: uniqFieldIdsArray,
			};

			categoryDict[varietyCategory.localizedName] = updatedVarietyEntry;
		} else {
			const newVarietyEntry = {
				category: varietyCategory.localizedName,
				areaSize: varietyShare * areaSize,
				areaUnit,
				fieldIds: [id],
			};

			categoryDict[varietyCategory.localizedName] = newVarietyEntry;
		}
	};

	fields.forEach((field) => {
		field.varieties?.forEach((variety) =>
			aggregateVarietyInDict(field, variety)
		);
	});

	const sortedVarieties = Object.values(categoryDict).sort(
		(a, b) => b.areaSize - a.areaSize
	);

	if (sortedVarieties.length > MAX_VAR_NUMBER) {
		const otherVarieties = sumOtherVarieties({
			varieties: sortedVarieties.slice(MAX_VAR_NUMBER),
			areaUnit,
			category: otherCategoryName,
		});

		const deleteCount = sortedVarieties.length - MAX_VAR_NUMBER;

		sortedVarieties.splice(MAX_VAR_NUMBER, deleteCount, otherVarieties);
	}

	return sortedVarieties;
}

export default getSummarySectionData;
