import { useTranslation } from 'Features/localization/hooks/useTranslation';
import formatForDisplay from 'Models/dates/formatForDisplay';
import { parseISO } from 'date-fns';

import getDateFnsLocale from 'Utils/getDateFnsLocale';
import stringToFirstUppercase from 'Utils/stringToFirstUppercase';

import useManageHistory from './useManageHistory';

const getFiltersString = (filterParam: string[], paramName: string): string => {
	return filterParam.length === 1
		? `[${stringToFirstUppercase(filterParam[0])}]`
		: `[${filterParam.length} ${paramName}]`;
};

function useParsedSearchParams(): string {
	const { t, i18n } = useTranslation();
	const { paramsFromURL: searchParams } = useManageHistory();
	const dateFnsOptions = {
		locale: getDateFnsLocale(i18n.language),
	};
	const searchFilters = searchParams.filters;
	const seasonFilter = searchFilters.season
		? `[${t('labels.season')} ${searchFilters.season}]`
		: null;
	const dataFilter =
		searchFilters.completedDateFrom && searchFilters.completedDateTo
			? `[
				${formatForDisplay(parseISO(searchFilters.completedDateFrom), dateFnsOptions)} -
				${formatForDisplay(parseISO(searchFilters.completedDateTo), dateFnsOptions)}
			]`
			: null;

	const jobTypes = searchFilters.jobTypes?.length
		? getFiltersString(
				searchFilters.jobTypes,
				t('labels.treatment', { count: searchFilters.jobTypes.length })
		  )
		: null;
	const fields = searchFilters.fieldIds?.length
		? getFiltersString(searchFilters.fieldIds, t('labels.fields'))
		: null;
	const statuses = searchFilters.jobStatuses?.length
		? getFiltersString(
				searchFilters.jobStatuses,
				t('labels.status', { count: searchFilters.jobStatuses?.length })
		  )
		: null;
	const categories = searchFilters.categoryIds?.length
		? getFiltersString(searchFilters.categoryIds, t('labels.categories'))
		: null;
	const fieldCategories = searchFilters.fieldCategories?.length
		? getFiltersString(searchFilters.fieldCategories, t('labels.categories'))
		: null;

	// todo: use partner location name for case when only one location is chosen #3263
	const locations = searchFilters.locations?.length
		? getFiltersString(
				searchFilters.locations,
				t('labels.location', { count: searchFilters.locations.length })
		  )
		: null;

	const parsedFilters = [
		seasonFilter,
		dataFilter,
		jobTypes,
		fields,
		statuses,
		categories,
		fieldCategories,
		locations,
	]
		.filter((item) => item != null)
		.join(' - ');

	return parsedFilters;
}

export default useParsedSearchParams;
