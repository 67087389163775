import { gql, TypedDocumentNode } from '@apollo/client';
import TPageInput from 'Models/api/PageInput';
import { TProduct } from 'Models/products/Product';
import { TProductFilterInput } from 'Models/products/types/ProductFilterInput';

type TGetCompanyTreatmentLibraryProductsInput = {
	filterBy: TProductFilterInput;
	pageInput?: TPageInput;
};

const GET_TREATMENT_LIBRARIES_PRODUCTS: TypedDocumentNode<
	{
		getCompanyTreatmentLibraryProducts: {
			products: Pick<TProduct, 'id' | 'name' | 'treatments'>[];
		};
	},
	TGetCompanyTreatmentLibraryProductsInput
> = gql`
	query getCompanyTreatmentLibraryProducts(
		$filterBy: ProductFilterInput!
		$pageInput: PageInput
	) {
		getCompanyTreatmentLibraryProducts(
			filterBy: $filterBy
			pageInput: $pageInput
		) {
			products {
				id
				name
				treatments {
					id
					amount
					varietyCategory {
						id
						name
						localizedName
						label
						categoryType
						categoryGroup
						faoCode
						siexCode
						swissCode
						swissLanguage
						germanyCode
						discriminator
					}
					unit
				}
			}
		}
	}
`;

export default GET_TREATMENT_LIBRARIES_PRODUCTS;
