import React from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { TProductTreatment } from 'Models/products/ProductTreatment';
import { TProductUnits } from 'Models/units';
import { toDisplayString } from 'Models/units/transpileUnits';
import Avatar from 'UI/display/Avatar';
import List from 'UI/display/List';
import Text from 'UI/display/Text';
import Box from 'UI/layout/Box';
import { FarmableColors, FarmablePlaceholdersColors } from 'UI/theme/Colors';

import usePartnerCompany from 'Hooks/usePartnerCompany';
import stringToInitials from 'Utils/stringToInitials';

import {
	useJobInputData,
	useAddJobModalStatus,
	addJobModalState,
} from '../../../../../state';

const colors = Object.values(FarmablePlaceholdersColors);

export type TMappedTreatment = {
	productName: string;
	treatment: TProductTreatment;
	isCustom: boolean;
};

type TProps = {
	treatment: TMappedTreatment;
	originalTreatmentId: string;
	onSelectCallback?: () => void;
};

function SelectTreatmentListItem(props: TProps) {
	const { t } = useTranslation();
	const { treatment, originalTreatmentId, onSelectCallback } = props;
	const {
		productName,
		treatment: { varietyCategory, amount, unit },
		isCustom,
	} = treatment;
	const jobInput = useJobInputData();
	const status = useAddJobModalStatus();
	const { company } = usePartnerCompany();

	const handleJobInput = (selectedTreatment: TMappedTreatment) => {
		const newTreatments = jobInput.jobDetails.treatments.map((treatment) => {
			const {
				treatment: { id, governmentTreatmentId, amount, unit },
				productName,
			} = selectedTreatment;
			return treatment.originalTreatmentId === originalTreatmentId
				? {
						originalTreatmentId: governmentTreatmentId || id || '',
						amount,
						unit,
						productName,
				  }
				: treatment;
		});

		addJobModalState({
			status,
			jobInputData: {
				...jobInput,
				jobDetails: {
					...jobInput.jobDetails,
					treatments: newTreatments,
				},
			},
		});
		onSelectCallback?.();
	};

	return (
		<List.Item
			onClick={() => handleJobInput(treatment)}
			secondaryAction={
				isCustom && company ? (
					<Avatar
						alt={company.name || ''}
						title={company.name || ''}
						src={company.image?.path}
						size="md"
						variant="circular"
						color={colors[(parseInt(company.id, 10) || 0) % colors.length]}
					>
						{stringToInitials(company.name || '')}
					</Avatar>
				) : null
			}
			sx={{
				cursor: 'pointer',
				'&.MuiListItem-root:hover': {
					backgroundColor: FarmableColors.BLACK_4,
				},
			}}
		>
			<Box display="flex" flexDirection="column">
				<Text>{productName}</Text>
				<Text variant="secondaryBody1">
					{varietyCategory.localizedName} - {amount}{' '}
					{t(`apiConstants.productUnits.${unit}`)}
				</Text>
			</Box>
		</List.Item>
	);
}

export default SelectTreatmentListItem;
