import React from 'react';

import { useQuery } from '@apollo/client';

import useManageHistory from 'Hooks/useManageHistory';

import TablePagination from '../../common/components/Pagination';
import GET_PARTNER_JOBS_TABLE_DATA from '../api/getPartnerJobsTableData';

const Pagination = () => {
	const manageHistory = useManageHistory();
	const searchParams = manageHistory.paramsFromURL;
	const pageInput = searchParams.pageInput;

	const { data, loading } = useQuery(GET_PARTNER_JOBS_TABLE_DATA, {
		variables: {
			filterBy: {
				jobFilters: {
					jobStatuses: searchParams.filters.jobStatuses,
					jobTypes: searchParams.filters.jobTypes,
					completedDateFrom: searchParams.filters.completedDateFrom,
					completedDateTo: searchParams.filters.completedDateTo,
					fieldCategoryIds: searchParams.filters.fieldCategories,
					fieldIds: searchParams.filters.fieldIds,
				},
				locationIds: searchParams.filters.locations,
			},
			pageInput,
			hasPagination: true,
		},
	});

	return (
		<TablePagination
			count={data?.getPartnerJobs.totalElements || 0}
			loading={loading}
		/>
	);
};

export default Pagination;
