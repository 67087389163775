import React, { useState, useMemo, useCallback } from 'react';

import { useQuery, useReactiveVar } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { LocationType } from 'Models/locations/isLocation';
import { GRAM_PER_100_LITER, ProductUnits } from 'Models/units';
import Divider from 'UI/display/Divider';
import FreeTextSearch from 'UI/inputs/FreeTextSearch';
import Radio from 'UI/inputs/Radio';
import RadioGroup from 'UI/inputs/RadioGroup';
import Box from 'UI/layout/Box';
import _sortBy from 'lodash/sortBy';
import _uniqBy from 'lodash/uniqBy';

import { GET_CATEGORIES } from '../../../../../../../api/getCategories';
import { addTreatmentPageState } from '../../../state';
import { TSelectCropCategoryInput } from '../../../types';

function SelectCropCategory() {
	const { t } = useTranslation();
	const [searchValue, setSearchValue] = useState('');
	const state = useReactiveVar(addTreatmentPageState);
	const { data } = useQuery(GET_CATEGORIES, {
		variables: { filterBy: { discriminator: LocationType.GLOBAL } },
	});

	const categories = useMemo(
		() =>
			_sortBy(
				_uniqBy(
					data?.getCategories.map(({ id, localizedName }) => ({
						id,
						localizedName,
					})),
					'id'
				),
				({ localizedName }) => localizedName.toLowerCase()
			),
		[data?.getCategories]
	);

	const cropCategoriesList = useMemo(
		() =>
			categories
				?.filter(({ localizedName }) =>
					localizedName.toLowerCase().includes(searchValue.toLowerCase())
				)
				.map(({ id, localizedName }) => (
					<Radio key={id} value={id} label={localizedName} />
				)),
		[categories, searchValue]
	);

	const setSelectedCrop = useCallback(
		(categoryId: string) => {
			addTreatmentPageState({
				...state,
				editedCategoryId: categoryId,
			} as TSelectCropCategoryInput);
		},
		[state]
	);

	if (state.status !== 'SELECT_CROP_CATEGORY') {
		return null;
	}

	return (
		<Box
			display="flex"
			flexDirection="column"
			overflow="auto"
			maxHeight="35.25rem"
		>
			<FreeTextSearch
				inputPlaceholder={t('labels.searchCrop')}
				onChange={(newValue: string): void => setSearchValue(newValue)}
				value={searchValue}
				sx={{
					height: '3.5rem',
					boxShadow: 'none',
					marginLeft: '-0.75rem',
				}}
			/>
			<Divider />
			<Box display="flex" overflow="auto" height="100%">
				<RadioGroup.Controlled
					value={state.editedCategoryId}
					valueChangeHandler={(e) => setSelectedCrop(e.target.value)}
					sx={{
						width: '100%',
						'.MuiFormControlLabel-root > .MuiRadio-root': {
							padding: '0.5rem 0.75rem',
						},
					}}
				>
					{cropCategoriesList?.length ? (
						cropCategoriesList
					) : (
						<Box marginY="1rem">{t('labels.noCategoriesFound')}</Box>
					)}
				</RadioGroup.Controlled>
			</Box>
		</Box>
	);
}

export default SelectCropCategory;
