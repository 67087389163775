import { URLParamsType } from 'Models/history/isURLParams';
import JobTypes, { TJobType } from 'Models/jobs/JobTypes';

function getQueryVariables(searchParams: URLParamsType) {
	return {
		filterBy: {
			fieldIds: searchParams.filters.fieldIds,
			jobStatuses: searchParams.filters.jobStatuses,
			fieldCategoryIds: searchParams.filters.fieldCategories,
			// to filter by customJobtypesIds, we need to add CUSTOM type for jobTypes filter
			jobTypes: searchParams.filters.customJobTypeIds?.length
				? [
						...(searchParams.filters.jobTypes || []),
						JobTypes.CUSTOM as TJobType,
				  ]
				: searchParams.filters.jobTypes,
			customJobTypeIds: searchParams.filters.customJobTypeIds,
			completedDateFrom: searchParams.filters.completedDateFrom,
			completedDateTo: searchParams.filters.completedDateTo,
			fieldVarieties: searchParams.filters.fieldVarieties,
		},
		locationID: searchParams.locationID,
		pageInput: searchParams.pageInput,
		hasPagination: true,
	};
}

export default getQueryVariables;
