import { gql, TypedDocumentNode } from '@apollo/client';

import { TCropCategoryData } from '../types';

const GET_CROP_SUMMARY_DATA: TypedDocumentNode<
	{
		getPartnershipDashboardFieldsCategories: TCropCategoryData[];
	},
	null
> = gql`
	query getCropSummaryData {
		getPartnershipDashboardFieldsCategories {
			varietyCategory {
				id
				name
				localizedName
				label
				categoryType
				categoryGroup
				faoCode
				siexCode
				swissCode
				swissLanguage
				germanyCode
				discriminator
			}
			areaSize
			fieldIds
		}
	}
`;

export default GET_CROP_SUMMARY_DATA;
