import formatISO from 'date-fns/formatISO';

import { TFieldVarietyInput } from '../../fields/isFieldVarietyInput';

const EMPTY_VARIETY: TFieldVarietyInput = {
	_id: '',
	name: '',
	varietyCategory: {
		id: '',
		name: '',
		localizedName: '',
		label: '',
		categoryType: '',
		categoryGroup: '',
		faoCode: 0,
		siexCode: 0,
		swissCode: 0,
		swissLanguage: '',
		germanyCode: '',
		discriminator: '',
	},
	percentage: null,
	identifier: undefined,
	rows: undefined,
	width: undefined,
	length: undefined,
	sowDate: formatISO(new Date()),
};

export default EMPTY_VARIETY;
