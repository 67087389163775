import getDateRepresentationFromString from 'Models/dates/getDateRepresentationFromString';
import { URLParamsType } from 'Models/history/isURLParams';
import QS from 'qs';
import URI from 'urijs';

const searchParamsToURL = (
	baseURL: string,
	params: Omit<URLParamsType, 'locationID'> & { locationID?: string }
): string => {
	const {
		jobStatuses,
		jobTypes,
		customJobTypeIds,
		categoryIds,
		completedDateFrom,
		completedDateTo,
		timeFrom,
		timeTo,
		teamId,
		fieldCategories,
		fieldIds,
		fieldVarieties,
		dateFrom,
		dateTo,
		customerId,
		onlySoldBatches,
		varieties,
		season,
		locations,
		partnerVarietyCategory,
		partnerVarietyName,
		movementTypes,
		search,
		teamMemberIds,
		teamLeadIds,
	} = params.filters;

	const { limit, offset, sortBy } = params.pageInput;

	const orderStatus = params.orderStatus;
	const demoReport = params.demoReport;

	const jt = jobTypes;
	const cjt = customJobTypeIds;
	const sts = jobStatuses;
	const cat = categoryIds;
	const pl = limit;
	const po = offset;
	const psb = sortBy;
	const cdf = getDateRepresentationFromString(completedDateFrom);
	const cdt = getDateRepresentationFromString(completedDateTo);
	const os = orderStatus;
	const dr = demoReport;
	const tf = getDateRepresentationFromString(timeFrom);
	const tt = getDateRepresentationFromString(timeTo);
	const ti = teamId;
	const fc = fieldCategories;
	const fi = fieldIds;
	const fv = fieldVarieties;
	const df = dateFrom;
	const dt = dateTo;
	const cid = customerId;
	const osb = onlySoldBatches;
	const vs = varieties;
	const sn = season;
	const ls = locations;
	const pvn = partnerVarietyName;
	const pvc = partnerVarietyCategory;
	const mt = movementTypes;
	const s = search;
	const tm = teamMemberIds;
	const tl = teamLeadIds;

	const query = {
		sts,
		jt,
		cjt,
		cat,
		pl,
		po,
		psb,
		cdf,
		cdt,
		os,
		dr,
		tf,
		tt,
		ti,
		fc,
		fi,
		fv,
		df,
		dt,
		cid,
		osb,
		vs,
		sn,
		ls,
		pvn,
		pvc,
		mt,
		s,
		tm,
		tl,
	};

	const parsedURL = new URI(baseURL);

	parsedURL.query(QS.stringify(query));
	return parsedURL.valueOf();
};

export default searchParamsToURL;
