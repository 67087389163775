import _intersection from 'lodash/intersection';

import { TFieldVariety } from './isFieldVariety';

type _TField<TGenericField> = TGenericField & {
	varieties: Pick<TFieldVariety, 'varietyCategory'>[] | null;
};

export function filterFieldsByCategory<TGenericField>(
	fields: _TField<TGenericField>[],
	categories?: string[]
): TGenericField[] {
	if (!categories || categories.length === 0) {
		return fields;
	}

	const filteredFields = fields.filter((field) => {
		const fieldCategoryIds =
			field.varieties?.map((variety) => variety.varietyCategory.id) || [];
		return _intersection(fieldCategoryIds, categories).length > 0;
	});

	return filteredFields;
}
