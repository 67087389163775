import { z } from 'zod';

import { TProductTreatmentTime, TreatmentTimes } from './productTreatmentTime';

export const ProductSpecInputSchema = z.object({
	registrationNumber: z.string().optional(),
	fracNumber: z.string().optional(),
	reEntryInterval: z.number().optional(),
	treatmentTime: z.enum(TreatmentTimes).optional(),
	note: z.string().optional(),
	manufacturerName: z.string().optional(),
	licenseExpirationDate: z.string().datetime().optional(),
	usageExpirationDate: z.string().datetime().optional(),
	governmentRemarks: z.array(z.string()).optional(),
});

export const SpanishProductSpecInputSchema =
	ProductSpecInputSchema &&
	z.object({
		traderRegistrationNumber: z.string(),
		fertilizerMaterialId: z.string(),
		organicCarbon: z.number(),
		manufacturerName: z.string(),
	});

export type TProductSpecInput = {
	registrationNumber?: string;
	fracNumber?: string;
	reEntryInterval?: number;
	treatmentTime?: TProductTreatmentTime;
	note?: string;
	manufacturerName?: string;
	licenseExpirationDate?: string | null;
	usageExpirationDate?: string | null;
	governmentRemarks?: string[];
	traderRegistrationNumber?: string;
	fertilizerMaterialId?: string;
	organicCarbon?: number;
};
