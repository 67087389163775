import React, { FC, useCallback, useState } from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import Text from 'UI/display/Text';
import Grid from 'UI/layout/Grid';
import { FarmableColors } from 'UI/theme/Colors';

import { ResetPasswordModal } from './ResetPasswordModal';

export const ResetPassword: FC = () => {
	const { t } = useTranslation();
	const [isConfirmModalOpened, setConfirmModalOpened] = useState(false);
	const handleOpenConfirmationModal = useCallback(
		() => setConfirmModalOpened(true),
		[]
	);
	const handleCloseConfirmationModal = useCallback(
		() => setConfirmModalOpened(false),
		[]
	);

	return (
		<>
			<Grid
				container
				item
				sx={{
					border: `1px solid ${FarmableColors.BLACK_12}`,
					padding: '1rem !important',
					borderRadius: '6px',
					margin: '1rem 0 0 1.5rem',
				}}
			>
				<Grid item xs={11}>
					<Text variant="body2" sx={{ fontWeight: 'bold' }}>
						{t('labels.password')}
					</Text>
					<Text>********</Text>
				</Grid>
				<Grid item xs={1}>
					<Text
						onClick={handleOpenConfirmationModal}
						variant="body2"
						sx={{
							cursor: 'pointer',
							fontWeight: 'bold',
							textDecoration: 'underline',
						}}
					>
						{t('buttons.resetPassword')}
					</Text>
				</Grid>
			</Grid>
			{isConfirmModalOpened && (
				<ResetPasswordModal handleClose={handleCloseConfirmationModal} />
			)}
		</>
	);
};
