export const treatmentLibraryTreatmentFragmentFields = `
id
varietyCategory {
	id
	name
	localizedName
	label
	categoryType
	categoryGroup
	faoCode
	siexCode
	swissCode
	swissLanguage
	germanyCode
	discriminator
}
amount
unit
governmentTreatmentId
waterMin
waterMax
waterUnit
treatmentCauses {
    id
    name
    createdAt
    discriminator

    causeType

    description
    spanishSpec {
      scientificName
      siexCode
      eppoCode
    }
    causeImages {
      id
      path
      filename
      mimetype
      createdAt
    }
}
withholdingPeriod
maxApplicationsVegetation

maxApplicationsCategory
maxApplicationsCause

applicationInterval
maxVolume
plantMilestones
registrationNumber
licenseExpirationDate
areaOfApplication
cultivationType
regulations
`;
