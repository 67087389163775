import React from 'react';

import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { TVarietyCategory } from 'Models/fields/isFieldVariety';
import { TPartnerVarietyInput } from 'Models/planner/PartnerVariety';
import Accordion from 'UI/display/Accordion';
import Text from 'UI/display/Text';
import ChevronRightIcon from 'UI/icons/ChevronRight';
import Radio from 'UI/inputs/Radio';
import RadioGroup from 'UI/inputs/RadioGroup';
import { FarmableColors } from 'UI/theme/Colors';

import translateProductCategory from 'Utils/translateProductCategory';

type TProps = {
	variety: string;
	varietyCategories: { name: string; varietyCategories: TVarietyCategory[] };
	selectedValue: TPartnerVarietyInput | null;
	onChangeHandler: (value: {
		varietyCategoryId: string;
		varietyCategoryName: string;
	}) => void;
};

const accordionHeaderStyles = {
	'&.MuiAccordionSummary-root': {
		flexDirection: 'row-reverse',
		minHeight: '2rem',
		'&:hover': {
			backgroundColor: FarmableColors.BLACK_4,
		},
	},
	'& .MuiAccordionSummary-expandIconWrapper': {
		transform: 'rotate(90deg)',
		marginRight: '0.5rem',
	},
	'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
		transform: 'rotate(-90deg)',
	},
	'&.Mui-expanded': {
		margin: 0,
		minHeight: '2rem',
	},
	'.MuiAccordionSummary-content, .MuiAccordionSummary-content.Mui-expanded': {
		margin: 0,
	},
};

const accordionStyles = {
	'&.MuiPaper-root': {
		boxShadow: 'none',
		width: '16.375rem',
		margin: 0,
	},
	'&:before': {
		display: 'none',
	},
};

const accordionDetailsStyles = {
	'.MuiFormControl-root': {
		width: '100%',
		'&:first-of-type': {
			marginTop: 0,
		},
	},
	'&.MuiAccordionDetails-root': {
		margin: 0,
		padding: 0,
	},
};

const radioGroupStyles = {
	'&.MuiFormGroup-root > *': {
		margin: 0,
	},
	'&.MuiFormGroup-root > *:hover': {
		backgroundColor: FarmableColors.BLACK_4,
	},
	'.MuiFormControlLabel-root': {
		padding: '0 1rem 0 2.75rem',
		width: '100%',
	},
	'.MuiFormControlLabel-root > .MuiRadio-root': {
		padding: '0.375rem',
	},
};

function selectedCropTypeToString(
	categoryId: string | undefined,
	varietyName: string | undefined
) {
	if (categoryId && varietyName) {
		return categoryId + '_' + varietyName;
	}

	return '';
}

function stringToSelectedCropType(cropType: string) {
	const [varietyCategoryId, varietyCategoryName] = cropType.split('_');
	return { varietyCategoryId, varietyCategoryName };
}

export default function CollapsedCategory(props: TProps) {
	const { variety, varietyCategories, selectedValue, onChangeHandler } = props;
	const { t } = useTranslation();
	const selectOptions = varietyCategories.varietyCategories.map(
		(varietyCategory) => {
			return (
				<Radio
					disableRipple
					key={selectedCropTypeToString(
						varietyCategory.id,
						varietyCategories.name
					)}
					value={selectedCropTypeToString(
						varietyCategory.id,
						varietyCategories.name
					)}
					label={varietyCategory.localizedName}
					size="small"
				/>
			);
		}
	);

	return (
		<Accordion.Uncontrolled sx={accordionStyles} square>
			<Accordion.Header
				sx={accordionHeaderStyles}
				expandIcon={<ChevronRightIcon sx={{ fontSize: '0.9rem' }} />}
			>
				<Text variant="body2">{translateProductCategory(variety, t)}</Text>
			</Accordion.Header>
			<Accordion.Content sx={accordionDetailsStyles}>
				<RadioGroup.Controlled
					value={selectedCropTypeToString(
						selectedValue?.categoryId,
						selectedValue?.name
					)}
					valueChangeHandler={(e) => {
						onChangeHandler(stringToSelectedCropType(e.target.value));
					}}
					sx={radioGroupStyles}
				>
					{selectOptions}
				</RadioGroup.Controlled>
			</Accordion.Content>
		</Accordion.Uncontrolled>
	);
}
