import isOneOf from 'vet/isOneOf';
import isShape from 'vet/objects/isShape';
import optional from 'vet/optional';
import isString from 'vet/strings/isString';
import ValidatorType from 'vet/types/ValidatorType';

import { isAreaUnit } from '../units';
import isLocationID from './isLocationID';

export const LocationType = {
	SIEX: 'SIEX',
	GLOBAL: 'GLOBAL',
	GERMAN: 'GERMAN',
	SWISS: 'SWISS',
} as const;

export type TLocationType = keyof typeof LocationType;

const _isLocation = isShape({
	id: isLocationID,
	name: optional(isString),
	address: optional(isString),
	city: optional(isString),
	zip: optional(isString),
	country: optional(isString),
	areaUnit: optional(isAreaUnit),
	seasonCutoffDate: optional(isString),
	businessName: optional(isString),
	orgNumber: optional(isString),
	createdAt: optional(isString),
	type: isOneOf(...Object.values(LocationType)),
});

// https://github.com/microsoft/TypeScript/issues/34596
const isLocation: typeof _isLocation = _isLocation;

export type TLocation = ValidatorType<typeof isLocation>;

export default isLocation;
