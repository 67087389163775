import React, { useMemo } from 'react';

import { useQuery } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import isJobStatus from 'Models/jobs/isJobStatus';
import translateJobStatus from 'Models/jobs/translateJobStatus';
import Permissions from 'Models/permissions/Permissions';

import useCompanyID from 'Hooks/useCompanyID';
import useManageHistory from 'Hooks/useManageHistory';
import usePermission from 'Hooks/usePermission';
import getDateFilterTag from 'Utils/getDateFilterTag';
import getDateFnsLocale from 'Utils/getDateFnsLocale';
import getLocationIdFromUrl from 'Utils/getLocationIdFromUrl';

import GET_CUSTOM_JOB_TYPES from '../../../../components/filters/Treatments/api/getCustomJobTypes';
import {
	GET_FIELDS_ADVISOR_QUERY,
	GET_FIELDS_QUERY,
} from '../../../../components/filters/common/api/getFields';
import GET_PARTNER_FIELDS_QUERY from '../../../../components/filters/common/api/getPartnerFields';
import FilterTag from './FilterTag';
import LocationFilterTag from './LocationFilterTag';

const Filters = () => {
	const { t, i18n } = useTranslation();
	const isPartner = Boolean(useCompanyID());
	const manageHistory = useManageHistory();

	const {
		categoryIds,
		completedDateFrom,
		completedDateTo,
		dateFrom,
		dateTo,
		fieldCategories,
		fieldIds,
		fieldVarieties,
		jobTypes,
		customJobTypeIds,
		locations,
		teamId,
		timeFrom,
		timeTo,
		varieties,
		season,
		jobStatuses,
		movementTypes,
		teamMemberIds,
		teamLeadIds,
	} = manageHistory.paramsFromURL.filters;
	const hasPermissionsToRegularQuery = usePermission(
		Permissions.GET_REGULAR_FIELD_CATEGORIES_QUERY
	);
	const locationId = getLocationIdFromUrl();

	const { data } = useQuery(GET_CUSTOM_JOB_TYPES, {
		skip: isPartner || !customJobTypeIds || customJobTypeIds.length > 1,
		variables: { locationId: manageHistory.paramsFromURL.locationID },
	});

	const { data: fieldsData } = useQuery(
		hasPermissionsToRegularQuery ? GET_FIELDS_QUERY : GET_FIELDS_ADVISOR_QUERY,
		{
			skip: isPartner,
			variables: {
				locationId: locationId,
			},
		}
	);
	const { data: partnerFields } = useQuery(GET_PARTNER_FIELDS_QUERY, {
		skip: !isPartner,
	});
	const translatedStatuses = jobStatuses
		?.filter(isJobStatus)
		.map((status) => translateJobStatus(status, t, isPartner));

	const dateFnsOptions = {
		locale: getDateFnsLocale(i18n.language),
	};

	const date = getDateFilterTag(
		dateFnsOptions,
		completedDateFrom || dateFrom || timeFrom,
		completedDateTo || dateTo || timeTo,
		season
	);

	const selectedFields = useMemo(
		() =>
			fieldIds && isPartner
				? partnerFields?.getPartnerFields.fields
						.filter(({ field }) => fieldIds.includes(field.id))
						.map(({ field }) => field.name || field.id)
				: fieldIds &&
				  fieldsData?.getFields.content
						.filter((field) => fieldIds.includes(field.id))
						.map((field) => field.name),
		[
			fieldIds,
			fieldsData?.getFields.content,
			isPartner,
			partnerFields?.getPartnerFields.fields,
		]
	);

	const jobTypesValue = jobTypes?.length
		? [...jobTypes, ...(customJobTypeIds || [])]
		: customJobTypeIds?.map(
				(cus) => data?.customJobTypes.find((e) => e.id === cus)?.name || cus
		  ) || [];

	const MultiChoiceFilterTag = (props: {
		values?: string[];
		labelName:
			| 'status'
			| 'field'
			| 'variety'
			| 'category'
			| 'treatment'
			| 'team'
			| 'movementType'
			| 'teamLead'
			| 'teamMembers';
	}) => {
		const { values, labelName } = props;
		return values?.length ? (
			<FilterTag
				name={t(`labels.${labelName}`, {
					count: values.length,
				})}
				value={
					values.length == 1 && labelName !== 'category'
						? values[0]
						: values.length
				}
			/>
		) : null;
	};

	return (
		<>
			{season ? <FilterTag name={t('labels.season')} value={season} /> : null}
			{date}
			<MultiChoiceFilterTag
				values={categoryIds || fieldCategories}
				labelName="category"
			/>
			<MultiChoiceFilterTag
				values={varieties || fieldVarieties}
				labelName="variety"
			/>
			{locations ? (
				<LocationFilterTag
					locations={locations}
					label={t('labels.location', {
						count: locations.length,
					})}
				/>
			) : null}
			<MultiChoiceFilterTag values={jobTypesValue} labelName="treatment" />
			<MultiChoiceFilterTag values={teamId} labelName="team" />
			<MultiChoiceFilterTag values={translatedStatuses} labelName="status" />
			<MultiChoiceFilterTag values={selectedFields} labelName="field" />
			<MultiChoiceFilterTag values={movementTypes} labelName="movementType" />
			<MultiChoiceFilterTag values={teamMemberIds} labelName="teamMembers" />
			<MultiChoiceFilterTag values={teamLeadIds} labelName="teamLead" />
		</>
	);
};

export default Filters;
