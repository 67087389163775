import { THarvestExportHeadersDictionary } from 'Features/localization/lang/types/exportPages';

import { TPartnerHarvest } from '../types';
import getHarvestRowExportData, { THarvestExportData } from './exportData';

type TExportData = {
	exportData: THarvestExportData[];
};

function getExportData(
	harvests: TPartnerHarvest[],
	dictionary: THarvestExportHeadersDictionary
): TExportData {
	const exportData = harvests.map((harvest) =>
		getHarvestRowExportData(harvest, dictionary)
	);

	return {
		exportData,
	};
}

export default getExportData;
