import { gql, TypedDocumentNode } from '@apollo/client';
import TPageInput from 'Models/api/PageInput';
import { THarvestFilterInput } from 'Models/harvest/types/HarvestFilterInput';
import { TLocation } from 'Models/locations/isLocation';
import { TPartnerHarvest } from 'Models/partnershipCompany/PartnerHarvest';

export type TPartnerHarvestTableData = Pick<
	TPartnerHarvest,
	| 'id'
	| 'date'
	| 'fields'
	| 'variety'
	| 'varietyCategory'
	| 'author'
	| 'volume'
	| 'boxSize'
	| 'quality'
	| 'countingUnit'
	| 'weight'
	| 'unit'
	| 'images'
	| 'location'
	| 'teamIds'
	| 'teamMembers'
	| 'comment'
	| 'boxName'
	| 'teamLeads'
>;

export type TPartnerLocationHarvestsResponse = {
	getPartnerHarvests: {
		totalPages: number;
		totalElements: number;
		content: {
			harvest: TPartnerHarvestTableData;
			location: Pick<TLocation, 'id' | 'name'>;
		}[];
	};
};
export type TPartnerLocationHarvestsRequest = {
	filterBy: {
		harvestFilters?: Partial<THarvestFilterInput>;
		locationIds?: string[];
	};
	pageInput: TPageInput;
	hasPagination: boolean;
};

const GET_PARTNER_HARVESTS_TABLE_DATA: TypedDocumentNode<
	TPartnerLocationHarvestsResponse,
	TPartnerLocationHarvestsRequest
> = gql`
	query getPartnerHarvestsTableData(
		$filterBy: PartnerHarvestFilterInput
		$pageInput: PageInput
	) {
		getPartnerHarvests(filterBy: $filterBy, pageInput: $pageInput) {
			totalPages
			totalElements
			content {
				location {
					id
					name
				}
				harvest {
					id
					date
					fields {
						id
						name
						areaSize
					}
					varietyCategory {
						id
						name
						localizedName
						label
						categoryType
						categoryGroup
						faoCode
						siexCode
						swissCode
						swissLanguage
						germanyCode
						discriminator
					}
					variety
					author {
						id
						firstName
						lastName
						profileImage {
							id
							path
							filename
							mimetype
							createdAt
						}
					}
					volume
					boxSize
					boxName
					quality
					countingUnit
					weight
					unit
					teamIds
					images {
						id
						path
						filename
						mimetype
						createdAt
					}
					teamMembers {
						id
						fullName
					}
					teamLeads {
						id
						firstName
						lastName
					}
					comment
				}
			}
		}
	}
`;

export default GET_PARTNER_HARVESTS_TABLE_DATA;
