import React, { FC, useCallback, useMemo, useState } from 'react';

import { useQuery, useReactiveVar } from '@apollo/client';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import { TProduct } from 'Models/products/Product';
import { TProductTreatmentInput } from 'Models/products/ProductTreatmentInput';
import { GovernmentProductTreatment } from 'Models/treatmentLibrary/governmentProductTreatment';
import List from 'UI/display/List';
import Text from 'UI/display/Text';
import LoadingSpinner from 'UI/icons/LoadingSpinner';
import SearchIcon from 'UI/icons/Search';
import TextField from 'UI/inputs/TextField';
import Box from 'UI/layout/Box';
import { FarmableColors } from 'UI/theme/Colors';
import { makeStyles } from 'UI/utils/makeStyles';
import { useTranslation } from 'react-i18next';

import usePartnerCompany from 'Hooks/usePartnerCompany';
import getTreatmentLibraryIdFromURL from 'Utils/getTreatmentLibraryIdFromURL';

import ModalSection from '../../../../../../../../../../components/ModalSection';
import GET_GOVERNMENT_CAUSES from '../../../../../../../../../planner/PlannerPage/components/modals/api/getGovernmentCauses';
import { GET_GOVERNMENT_TREATMENTS_FOR_TREATMENT_LIBRARY } from '../../../../../../../api';
import {
	makeGovernmentTreatmentInput,
	makeTreatmentInput,
} from '../../../../utils';
import {
	addTreatmentPageState,
	govTreatmentsInputState,
	treatmentInputState,
} from '../../../state';
import { AddTreatmentModalStatus } from '../../../types';

const useStyles = makeStyles(() => ({
	listItem: {
		paddingTop: '0',
		paddingBottom: '0',
		paddingRight: '0',
	},
	divider: {
		marginTop: '1.5rem',
		marginBottom: '1rem',
	},
	listTitle: {
		paddingBottom: '1rem',
	},
}));

export const SelectGovTreatments: FC = () => {
	const { t } = useTranslation();
	const classes = useStyles();
	const state = useReactiveVar(addTreatmentPageState);
	const [searchValue, setSearchValue] = useState('');
	const [searchCauseValue, setSearchCauseValue] = useState('');
	const [selectedCauses, setSelectedCauses] = useState([]);
	const libraryId = getTreatmentLibraryIdFromURL();
	const { company } = usePartnerCompany();
	const { data: govCauses } = useQuery(GET_GOVERNMENT_CAUSES, {
		skip: !company?.country,
		variables: company?.country
			? {
					filterBy: {
						country: company?.country,
						name: searchCauseValue,
					},
			  }
			: undefined,
	});

	const { data: govTreatments, loading } = useQuery(
		GET_GOVERNMENT_TREATMENTS_FOR_TREATMENT_LIBRARY,
		{
			variables: {
				libraryId: libraryId,
				filterBy: {
					productName: searchValue,
					causeIds: selectedCauses.map(({ id }) => id),
				},
				pageInput: {
					limit: 999,
					offset: 0,
					sortBy: [{ direction: 'ASC', field: 'productName' }],
				},
			},
		}
	);

	const handleTreatmentClick = (treatment: GovernmentProductTreatment) => {
		govTreatmentsInputState(treatment);
		treatmentInputState({
			governmentTreatmentId: treatment.id,
			categoryId: treatment.varietyCategory.id,
			causeIds: treatment.treatmentCauses.map(({ id }) => id),
			unit: treatment.unit,
			amount: treatment.amount,
		});
		addTreatmentPageState({
			...state,
			input: {
				...state.input,
				product: {
					treatments: [
						makeGovernmentTreatmentInput(treatment) as TProductTreatmentInput,
					],
				},
			},
			status: AddTreatmentModalStatus.GOV_TREATMENT_DETAILS,
		});
	};

	const causes = useMemo(
		() => govCauses?.governmentCauses.causes || [],
		[govCauses?.governmentCauses.causes]
	);

	const handleSelectCause = useCallback((_event, newValue) => {
		setSelectedCauses(newValue);
	}, []);

	return (
		<>
			<Box
				sx={{
					margin: 0,
					position: 'sticky',
					top: 0,
					backgroundColor: FarmableColors.WHITE,
					zIndex: 9999,
					paddingBottom: '1rem',
				}}
			>
				<Box>
					<Autocomplete
						options={causes}
						disableClearable
						filterSelectedOptions
						loading={loading}
						renderOption={(props, option) => (
							<li {...props} key={option.id + option.name}>
								{option.name}
							</li>
						)}
						getOptionLabel={(option) => option.name}
						renderInput={(params) => (
							<TextField
								{...params}
								label={t('treatmentLibrary.selectSource.searchCauses')}
								onChange={setSearchCauseValue}
								inputProps={{
									...params.inputProps,
									autoComplete: 'off',
								}}
							/>
						)}
						multiple
						value={selectedCauses}
						onChange={handleSelectCause}
					/>
				</Box>
				<Box sx={{ marginTop: '0.5rem' }}>
					<TextField
						fullWidth
						sx={{
							'& input': { padding: '1rem 0.5rem' },
							'& .MuiInputBase-adornedStart': { paddingLeft: '0.5rem' },
						}}
						placeholder={t(
							'treatmentLibrary.addTreatmentModal.searchProductInput.placeholder'
						)}
						onChange={(val: string) => setSearchValue(val)}
						value={searchValue}
						InputProps={{
							autoComplete: 'off',
							startAdornment: (
								<InputAdornment position="start">
									<SearchIcon fill={FarmableColors.BLACK_60} />
								</InputAdornment>
							),
						}}
					/>
				</Box>
			</Box>
			<Box display="flex" flexDirection="column" overflow="hidden">
				<ModalSection noDivider>
					{loading ? (
						<Box
							display="flex"
							justifyContent="center"
							alignItems="center"
							width="100%"
							height="100%"
							minHeight="60vh"
						>
							<LoadingSpinner />
						</Box>
					) : (
						<List.List>
							<Text
								fontWeight="bold"
								variant="body2"
								className={classes.listTitle}
							>
								{t('common.sidebar.treatments')}
							</Text>
							{govTreatments?.getGovernmentTreatmentsForTreatmentLibrary?.content.map(
								(treatment, idx) => {
									const unitText = t(
										`apiConstants.productUnits.${treatment.unit}`
									);

									return (
										<List.Item
											className={classes.listItem}
											key={idx}
											onClick={() => handleTreatmentClick(treatment)}
										>
											<List.ItemText
												sx={{ fontSize: 14, cursor: 'pointer' }}
												primary={`${treatment.productName} - ${treatment.amount} ${unitText}`}
												secondary={
													treatment.treatmentCauses?.length
														? treatment.treatmentCauses.map(({ name }) => name)
														: undefined
												}
											/>
										</List.Item>
									);
								}
							)}
						</List.List>
					)}
				</ModalSection>
			</Box>
		</>
	);
};
