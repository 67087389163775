import { gql, TypedDocumentNode } from '@apollo/client';
import { TFieldVariety } from 'Models/fields/isFieldVariety';

export type TFieldCategory = {
	id: string;
	name: string;
	varieties: Pick<TFieldVariety, 'id' | 'name' | 'varietyCategory'>[];
};

type TGetFieldsInput = {
	locationId: string;
};

export type TRegularFieldsResponse = {
	getFields: {
		content: TFieldCategory[];
	};
};

export type TAdvisorFieldsResponse = {
	getFieldsByUsersFieldCategories: TFieldCategory[];
};

export const GET_FIELDS_QUERY: TypedDocumentNode<
	TRegularFieldsResponse,
	TGetFieldsInput
> = gql`
	query getFieldsCategories($locationId: ID!) {
		getFields(locationId: $locationId) {
			content {
				id
				name
				varieties {
					name
					varietyCategory {
						id
						name
						localizedName
						label
						categoryType
						categoryGroup
						faoCode
						siexCode
						swissCode
						swissLanguage
						germanyCode
						discriminator
					}
				}
			}
		}
	}
`;

export const GET_FIELDS_ADVISOR_QUERY: TypedDocumentNode<
	TAdvisorFieldsResponse,
	TGetFieldsInput
> = gql`
	query getFieldsCategories($locationId: ID!) {
		getFieldsByUsersFieldCategories(locationId: $locationId) {
			id
			name
			varieties {
				id
				name
				varietyCategory {
					id
					name
					localizedName
					label
					categoryType
					categoryGroup
					faoCode
					siexCode
					swissCode
					swissLanguage
					germanyCode
					discriminator
				}
			}
		}
	}
`;
