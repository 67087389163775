import React, { useEffect } from 'react';

import { ApolloError } from '@apollo/client';
import { useQuery } from '@apollo/client/react';
import { analyticsTrack, TrackEvents } from 'Features/analytics';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import { THoursExportHeadersDictionary } from 'Features/localization/lang/types/exportPages';
import { TExportType } from 'Models/exports/ExportType';
import { LogTypes } from 'Models/logs/LogType';
import Text from 'UI/display/Text';
import Box from 'UI/layout/Box';

import useManageHistory from 'Hooks/useManageHistory';
import usePartnerCompany from 'Hooks/usePartnerCompany';
import useProfile from 'Hooks/useProfile';

import ExportLoadingSpinner from '../../../common/components/ExportLoadingSpinner';
import ExportPageLayout from '../../../common/components/ExportPageLayout';
import handleExport from '../../../common/utils/handlePartnerExport';
import GET_PARTNER_HOURS_EXPORT_DATA from '../api/getPartnerHoursFileExportData';
import getExportData from '../utils/getExportData';

type TProps = { type: Exclude<TExportType, 'PDF'> };

const LIMIT = 30;

function ExportToFile(props: TProps) {
	const { t } = useTranslation();
	const { paramsFromURL: searchParams } = useManageHistory();
	const { profile } = useProfile();
	const { company } = usePartnerCompany();
	const paramsFilters = searchParams.filters;

	const filterBy = {
		timesheetFilters: {
			timeFrom: paramsFilters.timeFrom,
			timeTo: paramsFilters.timeTo,
			categories: paramsFilters.categoryIds,
		},
		locationIds: paramsFilters.locations,
	};

	const { data, loading, error, fetchMore } = useQuery(
		GET_PARTNER_HOURS_EXPORT_DATA,
		{
			variables: {
				filterBy,
				pageInput: {
					offset: 0,
					limit: LIMIT,
					sortBy: [{ direction: 'DESC', field: 'startTime' }],
				},
			},
		}
	);

	const total = data?.getPartnerTimesheets.totalElements || 0;
	const rawTimesheets = data?.getPartnerTimesheets.timesheets;

	useEffect(() => {
		if (rawTimesheets && rawTimesheets.length < total) {
			void fetchMore({
				variables: {
					pageInput: { offset: rawTimesheets.length, limit: LIMIT },
				},
			});
		}
	}, [rawTimesheets, fetchMore, total]);

	const dictionary: THoursExportHeadersDictionary = t(
		'exportPages.headers.hours',
		{
			returnObjects: true,
		}
	);

	if (data && total === 0) {
		return (
			<Box padding="1.5rem">
				<Text>{t('labels.noLogsFound')}</Text>
			</Box>
		);
	}

	if (loading || (rawTimesheets && rawTimesheets.length < total)) {
		return <ExportLoadingSpinner />;
	}

	if (rawTimesheets) {
		const timesheets = rawTimesheets.map((timesheet) => ({
			...timesheet.timesheet,
			location: timesheet.location,
		}));

		const exportData = getExportData(timesheets, dictionary);

		return (
			<ExportPageLayout
				title={t('exportPages.readyTitle', { context: props.type })}
				downloadTrigger={() => {
					handleExport({
						exportData,
						fileType: props.type,
						logType: LogTypes.Hours,
						fileName: company?.name || 'Hours report',
					});
					if (profile && company) {
						analyticsTrack(TrackEvents.DataDownloaded, {
							userId: profile.id,
							groupId: company.id,
							exportType: props.type,
							logType: LogTypes.Hours,
						});
					}
				}}
			/>
		);
	}

	if (error) {
		throw new ApolloError(error);
	}

	return null;
}

export default ExportToFile;
