import { TMovementType } from 'Models/inventory/ProductInventoryLog';

import { TJobStatuses } from '../jobs/JobStatuses';
import { TJobType } from '../jobs/JobTypes';

export const CLEARED_FILTERS = {
	statuses: undefined,
	jobTypes: undefined,
	customJobTypeIds: undefined,
	categoryIds: undefined,
	completedDateFrom: undefined,
	completedDateTo: undefined,
	timeFrom: undefined,
	timeTo: undefined,
	teamId: undefined,
	fieldCategories: undefined,
	fieldIds: undefined,
	fieldVarieties: undefined,
	dateFrom: undefined,
	dateTo: undefined,
	customerId: undefined,
	onlySoldBatches: undefined,
	varieties: undefined,
	season: undefined,
	locations: undefined,
	partnerVarietyName: undefined,
	partnerVarietyCategory: undefined,
	search: undefined,
	movementTypes: undefined,
	teamMemberIds: undefined,
	teamLeadIds: undefined,
};

export type URLFiltersType = Partial<{
	movementTypes?: TMovementType[];
	productName?: string;
	teamMemberIds?: string[];
	teamLeadIds?: string[];
	jobTypes: TJobType[];
	jobStatuses: TJobStatuses[];
	customJobTypeIds: string[];
	categoryIds: string[];
	completedDateFrom: string;
	completedDateTo: string;
	timeFrom: string;
	timeTo: string;
	teamId: string[];
	fieldCategories: string[];
	fieldIds: string[];
	fieldVarieties: string[];
	dateFrom: string;
	dateTo: string;
	customerId: string;
	onlySoldBatches: boolean;
	varieties: string[];
	season: string;
	locations: string[];
	partnerVarietyName: string;
	partnerVarietyCategory: string;
	search: string;
}>;
