import { ReportType, TReportParams } from 'Models/exports/Report';

import { ReportFormInterface, TCreateReportType } from './CreateReport.types';

export const makeReportBody = (
	reportInput: ReportFormInterface,
	reportType: TCreateReportType
): TReportParams => {
	if ([ReportType.SPRAYING, ReportType.FERTILIZER].includes(reportType)) {
		return {
			dateFrom: reportInput.dateFrom,
			dateTo: reportInput.dateTo,
			status: reportInput.status,
			categoryIds: reportInput.categoryIds,
			fields: reportInput.fields,
			format: reportInput.format,
			breakdown: reportInput.breakdown,
		};
	} else if (ReportType.TIMESHEET === reportType) {
		return {
			dateFrom: reportInput.dateFrom,
			dateTo: reportInput.dateTo,
			teamId: reportInput.teamId,
			teamMemberIds: reportInput.teamMemberIds,
			fields: reportInput.fields,
			format: reportInput.format,
		};
	} else if (ReportType.PRODUCT_INVENTORY === reportType) {
		return {
			dateFrom: reportInput.dateFrom,
			dateTo: reportInput.dateTo,
			products: reportInput.products,
		};
	}

	//Harvest
	return {
		dateFrom: reportInput.dateFrom,
		dateTo: reportInput.dateTo,
		fields: reportInput.fields,
		varieties: reportInput.varieties,
		categoryIds: reportInput.categoryIds,
		quality: reportInput.quality.map(Number),
	};
};
