import React, { FC, useMemo } from 'react';

import { useQuery } from '@apollo/client';
import { useTranslation } from 'Features/localization/hooks/useTranslation';
import getDateRepresentationFromString from 'Models/dates/getDateRepresentationFromString';
import { ReportType } from 'Models/exports/Report';
import { filterFieldsByCategory } from 'Models/fields/filterFieldsByCategory';
import getLocationVarietyCategories from 'Models/fields/getLocationVarietyCategories';
import { TJobType } from 'Models/jobs/JobTypes';
import Text from 'UI/display/Text';
import { TOptions } from 'UI/inputs/DropdownWithChips';
import Box from 'UI/layout/Box';

import useLocationID from 'Hooks/useLocationID';
import stringToFirstUppercase from 'Utils/stringToFirstUppercase';

import { TFieldCategory } from '../../../../../components/filters/common/api/getFields';
import GET_JOBS_TABLE_DATA from '../../../JobLogsPage/api/getJobsTableData';
import {
	GET_HOURS_TOTAL_ELEMENTS,
	GET_TOTAL_HARVEST_ELEMENTS,
} from '../../CreateReport.queries';
import {
	ReportFormInterface,
	TCreateReportType,
} from '../../CreateReport.types';

interface CreateReportModalSummaryInterface {
	reportInput: ReportFormInterface;
	reportType: TCreateReportType;
	filteredFields: TFieldCategory[];
	productInventoryOptions: TOptions[];
}

const pageInput = {
	limit: 10,
	offset: 0,
	sortBy: [],
};

export const CreateReportModalSummary: FC<CreateReportModalSummaryInterface> =
	({ reportInput, productInventoryOptions, reportType, filteredFields }) => {
		const { t } = useTranslation();
		const locationID = useLocationID();
		const {
			dateFrom,
			dateTo,
			status,
			fields,
			categoryIds,
			teamId,
			teamMemberIds,
			varieties,
			quality,
			products,
		} = reportInput;

		const selectedProductsNames = useMemo(
			() =>
				products.map(
					(id) =>
						productInventoryOptions.find((product) => product.id === id)!.label
				),
			[productInventoryOptions, products]
		);

		const { data } = useQuery(GET_JOBS_TABLE_DATA, {
			variables: {
				filterBy: {
					jobStatuses: status,
					jobTypes: [reportType] as TJobType[],
					completedDateFrom: dateFrom,
					completedDateTo: dateTo,
					fieldCategoryIds: categoryIds,
					fieldIds: fields,
				},
				locationID: locationID,
				pageInput: pageInput,
			},
			skip: ![ReportType.SPRAYING, ReportType.FERTILIZER].includes(reportType),
		});

		const { data: hoursTotalData } = useQuery(GET_HOURS_TOTAL_ELEMENTS, {
			variables: {
				filterBy: {
					timeFrom: dateFrom,
					timeTo: dateTo,
					teamId: teamId,
					teamMemberIds: teamMemberIds,
					fieldIds: fields,
				},
				pageInput: pageInput,
				locationId: locationID,
			},
		});

		const { data: harvestTotalData } = useQuery(GET_TOTAL_HARVEST_ELEMENTS, {
			variables: {
				filterBy: {
					dateFrom: dateFrom,
					dateTo: dateTo,
					fieldVarieties: varieties,
					fieldIds: fields,
					fieldCategoryIds: categoryIds,
					quality: quality.map(Number)[0],
				},
				pageInput: pageInput,
				locationID: locationID,
			},
		});

		const selectedCategories = useMemo(
			() =>
				(categoryIds.length
					? getLocationVarietyCategories(
							filterFieldsByCategory(filteredFields, categoryIds).map(
								(variety) => variety
							)
					  )
					: getLocationVarietyCategories(filteredFields)
				)
					.map((variety) => ({ id: variety.id, label: variety.localizedName }))
					.filter(({ id }) => categoryIds.includes(id)),
			[categoryIds, filteredFields]
		);

		const FIELDS_MAP = useMemo(
			() => ({
				'logsPages.createReport.summary.dateFrom':
					getDateRepresentationFromString(dateFrom),
				'logsPages.createReport.summary.dateTo':
					getDateRepresentationFromString(dateTo),
				...([ReportType.SPRAYING, ReportType.FERTILIZER].includes(reportType)
					? {
							'buttons.jobType': t(
								`treatmentLogsExportPage.reportTitle.${reportType}`
							),
							'logsPages.jobLogsPage.tableHeaders.status':
								status.map(stringToFirstUppercase).join(', ') || '–',
							'logsPages.harvestLogsPage.detailsModal.props.category':
								selectedCategories
									.map(({ label }) => stringToFirstUppercase(label))
									.join(', ') || '–',
							'logsPages.harvestLogsPage.detailsModal.sections.fields':
								fields
									.map((selectedFieldId) =>
										stringToFirstUppercase(
											filteredFields.find(({ id }) => id === selectedFieldId)
												?.name || ''
										)
									)
									.join(', ') || '–',
					  }
					: ReportType.TIMESHEET === reportType
					? {
							'logsPages.harvestLogsPage.tableHeaders.teams': teamId || '–',
							'logsPages.harvestLogsPage.tableHeaders.teamMembers':
								teamMemberIds || '–',
					  }
					: ReportType.HARVEST === reportType
					? {
							'logsPages.harvestLogsPage.detailsModal.props.category':
								selectedCategories
									.map(({ label }) => stringToFirstUppercase(label))
									.join(', ') || '–',
							'logsPages.harvestLogsPage.detailsModal.sections.quality':
								quality.join(', ') || '–',
							'logsPages.jobLogsPage.detailsModal.sections.varieties':
								varieties.join(', ') || '–',
							'logsPages.harvestLogsPage.detailsModal.sections.fields':
								fields
									.map((selectedFieldId) =>
										stringToFirstUppercase(
											filteredFields.find(({ id }) => id === selectedFieldId)
												?.name || ''
										)
									)
									.join(', ') || '–',
					  }
					: reportType === ReportType.PRODUCT_INVENTORY
					? {
							'logsPages.harvestLogsPage.detailsModal.sections.products':
								selectedProductsNames.map(stringToFirstUppercase).join(', ') ||
								t('labels.all'),
					  }
					: {}),
			}),
			[
				categoryIds,
				dateFrom,
				dateTo,
				fields,
				filteredFields,
				quality,
				reportType,
				selectedProductsNames,
				status,
				t,
				teamId,
				teamMemberIds,
				varieties,
			]
		);

		return (
			<>
				<Text
					fontSize="0.875rem"
					fontWeight="bold"
					lineHeight="20px"
					marginBottom="0.5rem"
				>
					{t('logsPages.createReport.summary.title')}
				</Text>
				<Box display="flex" flexDirection="column">
					{Object.keys(FIELDS_MAP).map((key) => {
						return (
							<Box
								display="flex"
								alignItems="flex-start"
								flex={1}
								key={key}
								flexWrap="wrap"
							>
								<Text variant="label4" lineHeight="20px" flexBasis="40%">
									{/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
									{/*@ts-expect-error*/}
									{`${t(key)}:`}
								</Text>
								<Box display="flex" flexWrap="wrap" flex={1}>
									<Text variant="label4" lineHeight="20px">
										{FIELDS_MAP[key]}
									</Text>
								</Box>
							</Box>
						);
					})}
					{reportType !== ReportType.PRODUCT_INVENTORY && (
						<Box display="flex" alignItems="flex-start" flex={1}>
							<Text
								variant="label4"
								lineHeight="20px"
								flexBasis="40%"
								fontWeight="bold"
							>
								{`${t(
									reportType === ReportType.TIMESHEET
										? 'logsPages.createReport.summary.totalHours'
										: reportType === ReportType.HARVEST
										? 'logsPages.createReport.summary.totalHarvests'
										: 'logsPages.createReport.summary.totalJobs'
								)}:`}
							</Text>
							<Box display="flex" flexWrap="wrap" flex={1}>
								<Text variant="label4" lineHeight="20px" fontWeight="bold">
									{reportType === ReportType.TIMESHEET
										? hoursTotalData?.getTimesheets.totalElements
										: reportType === ReportType.HARVEST
										? harvestTotalData?.getHarvest.totalElements
										: data?.getJobs.totalElements || '–'}
								</Text>
							</Box>
						</Box>
					)}
				</Box>
			</>
		);
	};
