import { gql, TypedDocumentNode } from '@apollo/client';
import { TField } from 'Models/fields/isField';

export type TGetDashboardFieldResponse = {
	getField: Pick<TField, 'id' | 'name' | 'geometry' | 'varieties' | 'areaSize'>;
};

type TGetDashboardFieldRequest = {
	locationId: string;
	id: string;
};

const GET_DASHBOARD_FIELD_QUERY: TypedDocumentNode<
	TGetDashboardFieldResponse,
	TGetDashboardFieldRequest
> = gql`
	query getDashboardField($id: ID!, $locationId: ID!) {
		getField(id: $id, locationId: $locationId) {
			id
			name
			geometry {
				x
				y
			}
			varieties {
				identifier
				name
				varietyCategory {
					id
					name
					localizedName
					label
					categoryType
					categoryGroup
					faoCode
					siexCode
					swissCode
					swissLanguage
					germanyCode
					discriminator
				}
				percentage
			}
			areaSize
		}
	}
`;

export default GET_DASHBOARD_FIELD_QUERY;
